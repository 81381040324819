import * as React from 'react'
import { useDispatch } from 'react-redux'

import { Icon } from 'components/common/svg-icon'
import { OverridenNameContainer, OverridenNameTooltip } from './overriden-name-icon.styles'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { showToast } from 'com.batch.redux/toaster'

type Props = { name: string }

export const OverriddenNameIcon = ({ name }: Props): React.ReactElement => {
  const dispatch = useDispatch()

  const handleCopyClick = React.useCallback(() => {
    if (name) {
      const TempText = document.createElement('input')
      TempText.value = name
      document.body.appendChild(TempText)
      TempText.select()

      document.execCommand('copy')
      document.body.removeChild(TempText)
      dispatch(showToast({ kind: 'success', message: 'Attribute id copied' }))
    }
  }, [name, dispatch])

  return (
    <Tooltip
      tooltip={
        <OverridenNameTooltip>
          <div>{name}</div>
          <div>Click to copy</div>
        </OverridenNameTooltip>
      }
      placement="bottom-start"
    >
      <OverridenNameContainer onClick={handleCopyClick}>
        <Icon icon="sharp" />
      </OverridenNameContainer>
    </Tooltip>
  )
}
