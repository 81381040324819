import styled from 'styled-components'

import { texts } from 'components/styled/tokens'

export const EmptySearchWrapper = styled.div`
  height: 376px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    &:first-of-type {
      margin-bottom: 8px;
      font: ${texts.h2.font};
    }

    i {
      margin-right: 8px;
    }
  }
`
