import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { Button, DropdownMenu, useDropdown } from 'components/common/button'
import { HorizontalDivider } from 'components/styled/utils'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'
import { restoreData, setEditingId } from 'com.batch/data-management/usecases/update-data'
import { showToast } from 'com.batch.redux/toaster'
import { useDispatch } from 'com.batch.common/react-redux'
import { WarningButton } from 'com.batch/data-management/ui/components/warning-button.styles'

type Props = {
  name: string
  kind: DataKind
  isArchived?: boolean
  onClickOverrideType?: () => void
  onClickArchive: () => void
}

export const DataTableMoreOptions = ({
  name,
  kind,
  isArchived = false,
  onClickOverrideType,
  onClickArchive,
}: Props): React.ReactNode => {
  const [isLoading, setIsLoading] = React.useState(false)
  const dispatch = useDispatch()
  const { triggerProps, dropdownProps, closeDropdown } = useDropdown({
    forcedWidth: 160,
    placement: 'bottom-end',
  })

  const handleRenameClick = React.useCallback(() => {
    dispatch(setEditingId(name))
    closeDropdown()
  }, [closeDropdown, dispatch, name])

  const handleOverrideTypeClick = React.useCallback(() => {
    if (onClickOverrideType) {
      onClickOverrideType()
    }
    closeDropdown()
  }, [closeDropdown, onClickOverrideType])

  const handleRestoreClick = React.useCallback(() => {
    if (isLoading) return null
    setIsLoading(true)
    dispatch(restoreData({ name, kind }))
      .then(() => {
        dispatch(
          showToast({
            message: kind === DataKind.ATTRIBUTE ? 'Attribute restored' : 'Event restored',
            kind: 'success',
          })
        )
        closeDropdown()
      })
      .catch(e => dispatch(showToast({ message: e, kind: 'error' })))
      .finally(() => setIsLoading(false))
  }, [closeDropdown, dispatch, isLoading, kind, name])

  const handleArchiveClick = React.useCallback(() => {
    onClickArchive()
    closeDropdown()
  }, [onClickArchive, closeDropdown])

  return (
    <React.Fragment>
      <Button {...triggerProps}>
        <Icon icon="options" />
      </Button>
      <DropdownMenu {...dropdownProps}>
        <Button addOn="prefix" onClick={handleRenameClick}>
          <Icon icon="edit" />
          Rename
        </Button>
        {kind === DataKind.ATTRIBUTE && (
          <Button addOn="prefix" onClick={handleOverrideTypeClick}>
            <Icon icon="edit" />
            Override Type
          </Button>
        )}
        <HorizontalDivider style={{ marginBottom: 4 }} />
        {isArchived ? (
          <WarningButton
            intent="danger"
            addOn="prefix"
            onClick={handleRestoreClick}
            disabled={isLoading}
            className="warning-button"
          >
            <Icon icon="history" /> Restore
          </WarningButton>
        ) : (
          <WarningButton
            intent="danger"
            addOn="prefix"
            onClick={handleArchiveClick}
            className="warning-button"
          >
            <Icon icon="archive" /> Archive
          </WarningButton>
        )}
      </DropdownMenu>
    </React.Fragment>
  )
}
