import * as React from 'react'
import { ThemeContext } from 'styled-components'
import { TableTemplateCell, EmptyCell } from 'components/common/table'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

type Props = {
  kind: DataKind
}

const DataTableTemplateRaw = ({ kind }: Props): React.ReactElement => {
  const themeContext = React.useContext(ThemeContext)
  const isLoading = (!!themeContext && themeContext.isLoading) ?? false

  return (
    <React.Fragment>
      <TableTemplateCell template="14px 194px" gap={8} />
      {kind === DataKind.EVENT && <TableTemplateCell template="26px 14px" gap={8} />}
      <TableTemplateCell template="84px" />
      <div>
        <EmptyCell
          $theme={isLoading ? 'loading' : 'empty'}
          $width="100px"
          style={{ height: 24, borderRadius: 19 }}
        />
      </div>
      <TableTemplateCell template="84px" />
      <TableTemplateCell template="14px" align="flex-end" />
    </React.Fragment>
  )
}

export const DataTableTemplate = React.memo(DataTableTemplateRaw)
