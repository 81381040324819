import * as React from 'react'
import { type Map } from 'immutable'

import { type availableIcons, Icon } from 'components/common/svg-icon'
import { SourcesWrapper, SourcesTooltip, Dot } from './sources.styles'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { humanizeDayjs } from 'com.batch.common/utils'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'

import { ApiBadge } from './api-badge'

const SOURCES: { icon: availableIcons; title: string }[] = [
  { icon: 'ios', title: 'iOS SDK' },
  { icon: 'android', title: 'Android SDK' },
  { icon: 'webpush', title: 'Web SDK' },
  { icon: 'api', title: 'APIs' },
]

type Props = {
  sources: Map<string, string>
}

export const Sources = ({ sources }: Props): React.ReactNode => {
  const tooltipContent = React.useMemo(
    () => (
      <React.Fragment>
        {SOURCES.map(
          source =>
            sources.has(source.icon) && (
              <SourcesTooltip key={source.icon}>
                <div style={source.icon === 'api' ? { alignItems: 'center' } : {}}>
                  {source.icon === 'api' ? <ApiBadge /> : <Icon icon={source.icon} />}
                  {source.title}
                </div>
                <div>{humanizeDayjs({ date: dayjs(sources.get(source.icon)), suffix: 'ago' })}</div>
              </SourcesTooltip>
            )
        )}
      </React.Fragment>
    ),
    [sources]
  )

  return (
    <Tooltip tooltip={tooltipContent} placement="bottom-start" padding={0} width={228}>
      <SourcesWrapper>
        {SOURCES.map(source => (
          <div key={source.icon}>
            {sources.has(source.icon) ? <Icon icon={source.icon} /> : <Dot />}
          </div>
        ))}
      </SourcesWrapper>
    </Tooltip>
  )
}
