import Immutable, { type RecordOf } from 'immutable'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

export type ConfirmedCustomData = {
  name: string
  kind: DataKind
}

export type ConfirmedCustomDataRecord = RecordOf<ConfirmedCustomData>

export const ConfirmedCustomDataFactory = Immutable.Record<ConfirmedCustomData>({
  name: '',
  kind: DataKind.ATTRIBUTE,
})
