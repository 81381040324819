import {
  type DispatchBoundFn,
  type DispatchExtraBoundFn,
  type ReduxAction,
} from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import {
  UpdateCustomAttributeFactory,
  type UpdateCustomAttributeRecord,
  UpdateCustomEventFactory,
  type UpdateCustomEventRecord,
  UpdateCustomTagFactory,
  type UpdateCustomTagRecord,
} from 'com.batch/data-management/models/update-custom-attribute.record'
import type { TypeValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

export type UpdateDataReturned = {
  attribute?: UpdateCustomAttributeRecord
  event?: UpdateCustomEventRecord
  tag?: UpdateCustomTagRecord
}

type UpdateAttributeAction = ReduxAction<'UPDATE_ATTRIBUTE', null>
export type UpdateAttributeSuccessAction = ReduxAction<
  'UPDATE_ATTRIBUTE_SUCCESS',
  UpdateDataReturned
>
type UpdateAttributeFailureAction = ReduxAction<'UPDATE_ATTRIBUTE_FAILURE', string>

type UpdateEventAction = ReduxAction<'UPDATE_EVENT', null>
export type UpdateEventSuccessAction = ReduxAction<'UPDATE_EVENT_SUCCESS', UpdateDataReturned>
type UpdateEventFailureAction = ReduxAction<'UPDATE_EVENT_FAILURE', string>

type SetEditingIdAction = ReduxAction<'SET_EDITING_ID', string | null>

export type UpdateDataActions =
  | UpdateAttributeAction
  | UpdateAttributeSuccessAction
  | UpdateAttributeFailureAction
  | UpdateEventAction
  | UpdateEventSuccessAction
  | UpdateEventFailureAction
  | SetEditingIdAction

export const setEditingId =
  (id: string | null): DispatchBoundFn<void> =>
  dispatch => {
    dispatch({
      type: 'SET_EDITING_ID',
      payload: id,
    })
  }

export const restoreData = ({
  name,
  kind,
}: {
  name: string
  kind: DataKind
}): DispatchExtraBoundFn<Promise<void>> => {
  return async dispatch => {
    await dispatch(updateData({ name, kind, isArchived: false }))
  }
}

export const archiveData = ({
  name,
  kind,
}: {
  name: string
  kind: DataKind
}): DispatchExtraBoundFn<Promise<void>> => {
  return async dispatch => {
    await dispatch(updateData({ name, kind, isArchived: true }))
  }
}

export const updateData = ({
  name,
  kind,
  overriddenName,
  isArchived,
  overriddenType,
}: {
  name: string
  kind: DataKind
  overriddenName?: string
  isArchived?: boolean
  overriddenType?: TypeValue
}): DispatchExtraBoundFn<Promise<UpdateDataReturned>> => {
  return async (dispatch, getState, { dmService }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: dmService.update({
        attribute:
          kind === DataKind.ATTRIBUTE
            ? UpdateCustomAttributeFactory({
                projectKey: {
                  textual: {
                    text: project.projectKey,
                  },
                },
                name,
                isArchived,
                overriddenName,
                overriddenType,
              })
            : undefined,
        event:
          kind === DataKind.EVENT
            ? UpdateCustomEventFactory({
                projectKey: {
                  textual: {
                    text: project.projectKey,
                  },
                },
                name,
                isArchived,
                overriddenName,
              })
            : undefined,
        tag:
          kind === DataKind.TAGS
            ? UpdateCustomTagFactory({
                projectKey: {
                  textual: {
                    text: project.projectKey,
                  },
                },
                name,
                isArchived,
                overriddenName,
              })
            : undefined,
      }),
      actionName:
        kind === DataKind.ATTRIBUTE || kind === DataKind.TAGS ? 'UPDATE_ATTRIBUTE' : 'UPDATE_EVENT',
    })
  }
}
