import * as React from 'react'

import { Popin } from 'components/common/popin/popin'
import { BoxBody, BoxHeader, BoxTitle, BoxFooter } from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { type TypeValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { type Type, types } from 'com.batch/data-management/constants/type'
import { Select } from 'components/form'
import { useDispatch } from 'com.batch.common/react-redux'
import { updateData } from 'com.batch/data-management/usecases/update-data'
import { showToast } from 'com.batch.redux/toaster'
import { DataModalBox } from 'com.batch/data-management/ui/components/data-modal.styles'
import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'

type Props = {
  attribute: CustomDataRecord | null
  onClose: () => void
}

const optToString = (opt?: { label: string; value: TypeValue } | null) => opt?.label ?? ''

export const OverrideTypeModal = ({ attribute, onClose }: Props) => {
  const dispatch = useDispatch()
  const [selectedType, setSelectedType] = React.useState<Type | null>(null)

  const type = React.useMemo(
    () => attribute?.overriddenType ?? attribute?.lastReceivedType ?? null,
    [attribute]
  )
  const isDisabled = React.useMemo(
    () => !selectedType?.value || selectedType?.value === type,
    [selectedType?.value, type]
  )

  const optionFormatter = React.useCallback((opt: Type) => {
    return (
      <span style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {opt.icon && <Icon icon={opt.icon} />}
        {opt.label}
      </span>
    )
  }, [])

  const handleOnChange = React.useCallback((value: Type) => {
    setSelectedType(value)
  }, [])

  const handleOnClickOverride = React.useCallback(() => {
    if (attribute) {
      dispatch(
        updateData({
          name: attribute.name,
          kind: attribute.kind,
          overriddenType: selectedType?.value,
        })
      )
        .then(() => {
          dispatch(showToast({ message: 'Attribute type overridden', kind: 'success' }))
        })
        .catch(e => dispatch(showToast({ message: e, kind: 'error' })))
      onClose()
    }
  }, [attribute, dispatch, onClose, selectedType?.value])

  React.useEffect(() => {
    setSelectedType(types.find(t => t.value === type) ?? null)
  }, [attribute, type])

  return (
    <Popin opened={Boolean(attribute)} close={onClose}>
      <DataModalBox>
        <BoxHeader>
          <BoxTitle>Override attribute type</BoxTitle>
          <Button onClick={onClose}>
            <Icon icon="close" />
          </Button>
        </BoxHeader>
        <BoxBody style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <div>
            <p style={{ marginBottom: 7 }}>
              If your implementation accidentally sends different data types for the same attribute,
              your targeting might be affected as Batch will always consider the last type received.
            </p>
            <p>You can avoid this by forcing the expected attribute type.</p>
          </div>
          <Select
            value={selectedType}
            optionFormatter={optionFormatter}
            optionToString={optToString}
            onChange={handleOnChange}
            options={types}
            style={{ width: 220 }}
            isSearchable
          />
        </BoxBody>
        <BoxFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            intent="action"
            kind="primary"
            onClick={handleOnClickOverride}
            disabled={isDisabled}
          >
            Override
          </Button>
        </BoxFooter>
      </DataModalBox>
    </Popin>
  )
}
