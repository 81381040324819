import styled from 'styled-components'
import { colors, texts, schemes } from 'components/styled/tokens'

export const OverridenNameContainer = styled.div`
  margin-left: 8px;
  color: ${colors.textDisabled};
  cursor: pointer;
`

export const OverridenNameTooltip = styled.div`
  div:first-of-type {
    font-family: ${texts.code.family};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.textContrast};
  }

  div:last-of-type {
    color: ${schemes.lightlucent['60']};
  }
`
