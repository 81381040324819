import { useTransition, useSpring, animated } from '@react-spring/web'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { CollapsePanelContainer, CollapsePanelContent } from './collapse.styles'

type CollapseChevronProps = {
  isOpened: boolean
  openingDirection?: 'up' | 'down'
}
export const CollapseChevron = ({
  isOpened,
  openingDirection = 'down',
}: CollapseChevronProps): React.ReactElement => {
  const states = {
    from: {
      transform: `rotate(${openingDirection === 'down' ? 0 : 180}deg)`,
    },
    to: {
      transform: `rotate(${openingDirection === 'down' ? 180 : 0}deg)`,
    },
  }
  const props = useSpring({
    ...(isOpened ? states.to : states.from),
    config: {
      duration: 100,
    },
  })

  return (
    <animated.div style={props}>
      <Icon icon={'chevron-down'} style={{ position: 'relative', bottom: 2 }} />
    </animated.div>
  )
}

export type CollapseProps = {
  children: React.ReactNode
  btnLabel: string
  id?: string
  openingDirection?: 'up' | 'down'
}

export const Collapse = ({
  children,
  btnLabel,
  id,
  openingDirection = 'down',
}: CollapseProps): React.ReactElement => {
  const [isOpened, setIsOpened] = React.useState(false)
  const handleOnClick = React.useCallback(() => setIsOpened(prev => !prev), [setIsOpened])
  const panelId = React.useId()
  return (
    <React.Fragment>
      <h3>
        <Button
          kind="discreet"
          addOn="prefix"
          onClick={handleOnClick}
          aria-expanded={isOpened}
          aria-controls={id}
        >
          {btnLabel}
          <CollapseChevron isOpened={isOpened} openingDirection={openingDirection} />
        </Button>
      </h3>
      <CollapsePanel isOpened={isOpened} isStandalone={false} id={id ?? panelId}>
        {children}
      </CollapsePanel>
    </React.Fragment>
  )
}

Collapse.displayName = 'Collapse'
export type CollapsePanelProps = {
  isOpened: boolean
  children: React.ReactNode
  isStandalone?: boolean
  scrollIntoView?: boolean
  id: string
  disableAnimation?: boolean
}
export const CollapsePanel: React.ComponentType<CollapsePanelProps> = React.memo(
  ({ isOpened, children, isStandalone = true, scrollIntoView = false, id, disableAnimation }) => {
    const collapseRef = React.useRef<HTMLDivElement | null>(null)

    const transformProps = React.useMemo(() => {
      const initial = disableAnimation
        ? {
            opacity: 1,
            gridTemplateRows: '1fr',
          }
        : {
            opacity: 0,
            gridTemplateRows: '0fr',
          }

      return {
        initial,
        from: initial,
        leave: initial,
        enter: {
          opacity: 1,
          gridTemplateRows: '1fr',
        },
        onChange: () => {
          if (scrollIntoView) collapseRef.current?.scrollIntoView({ behavior: 'instant' })
        },
      }
    }, [disableAnimation, scrollIntoView])
    const transitions = useTransition(isOpened, transformProps)

    return transitions((style, item) =>
      item ? (
        <CollapsePanelContainer
          id={id}
          ref={collapseRef}
          style={style}
          $isStandalone={isStandalone}
        >
          <CollapsePanelContent>{children}</CollapsePanelContent>
        </CollapsePanelContainer>
      ) : null
    )
  }
)
CollapsePanel.displayName = 'CollapsePanel'
