import * as React from 'react'
import { useSelector } from 'react-redux'

import {
  attributesSelector,
  eventsSelector,
  newAttributesSelector,
  newEventsSelector,
} from 'com.batch/data-management/store/data-management.selectors'

import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'
import { DataTable } from 'com.batch/data-management/ui/components/data-table/data-table'
import { NewDataBlock } from 'com.batch/data-management/ui/components/new-data-block/new-data-block'
import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { EmptyTabTitle } from './data-tab.styles'

import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'
import { type List } from 'immutable'
import { type LoadingStatus } from 'constants/common'

type Props = {
  isArchivedShown: boolean
  kind: DataKind
  loadingState: LoadingStatus
  onClickArchive: (data: CustomDataRecord) => void
  onClickOverrideType: (data: CustomDataRecord) => void
  searchTerms: string | null
  toggleConfirmModal: (isOpen: boolean) => () => void
}

export const DataTab = ({
  isArchivedShown,
  kind,
  loadingState,
  onClickArchive,
  onClickOverrideType,
  searchTerms,
  toggleConfirmModal,
}: Props): React.ReactElement => {
  const newData = useSelector(
    kind === DataKind.ATTRIBUTE ? newAttributesSelector : newEventsSelector
  )
  const data = useSelector(kind === DataKind.ATTRIBUTE ? attributesSelector : eventsSelector)

  const filter = React.useCallback(
    (data: List<CustomDataRecord>) => {
      return data.filter(
        d =>
          (d.overriddenName ?? d.name).toLowerCase().includes(searchTerms?.toLowerCase() ?? '') &&
          (!d.archivedAt || isArchivedShown)
      )
    },
    [isArchivedShown, searchTerms]
  )

  const filteredData = React.useMemo(() => filter(data), [filter, data])
  const newFilteredData = React.useMemo(() => filter(newData), [filter, newData])
  const hasNewData = React.useMemo(() => newFilteredData.size > 0, [newFilteredData])
  const hasData = React.useMemo(() => filteredData.size > 0, [filteredData])

  const showDataTable = React.useMemo(
    () => hasData || (!hasData && !hasNewData),
    [hasNewData, hasData]
  )

  const isLoading = React.useMemo(
    () => loadingState === 'LOADING' || loadingState === 'INIT',
    [loadingState]
  )
  const isEmpty = React.useMemo(
    () => loadingState === 'LOADED' && !hasNewData && !hasData && !searchTerms,
    [loadingState, hasNewData, hasData, searchTerms]
  )
  const hasErrors = React.useMemo(() => loadingState === 'ERROR', [loadingState])

  return (
    <Wrapper
      isEmpty={isEmpty || hasErrors}
      isLoading={isLoading}
      isOverlayShown={isEmpty || hasErrors}
      overlayProps={
        hasErrors
          ? GlobalErrorOverlayProps
          : {
              status: 'empty-page',
              description: (
                <React.Fragment>
                  <EmptyTabTitle>
                    No {kind === DataKind.EVENT ? 'event' : 'attribute'} available
                  </EmptyTabTitle>
                  <div>
                    Start collecting new attributes to review, confirm and manage them here.
                  </div>
                </React.Fragment>
              ),
            }
      }
    >
      {hasNewData && (
        <NewDataBlock
          kind={kind}
          datas={newFilteredData}
          confirmAll={toggleConfirmModal(true)}
          onClickArchive={onClickArchive}
          onClickOverrideType={onClickOverrideType}
          searchTerms={searchTerms}
          isArchivedShown={isArchivedShown}
        />
      )}
      {showDataTable && (
        <DataTable
          kind={kind}
          datas={filteredData}
          onClickArchive={onClickArchive}
          onClickOverrideType={onClickOverrideType}
          isArchivedShown={isArchivedShown}
          searchTerms={searchTerms}
        />
      )}
    </Wrapper>
  )
}
