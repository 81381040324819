import Immutable, { type RecordOf } from 'immutable'
import { EventAttributeKind } from 'com.batch/data-management/infra/types/event-attribute.types'
import { type TypeValue } from 'com.batch/profile/infra/debug/models/shared.model'

export type EventAttributeProps = {
  name: string
  type: TypeValue
  kind: EventAttributeKind
}
export type EventAttributeRecord = RecordOf<EventAttributeProps>

export const EventAttributeFactory = Immutable.Record<EventAttributeProps>({
  name: '',
  type: 'OBJECT',
  kind: EventAttributeKind.OBJECT,
})
