import { LoadingStatus } from 'constants/common'
import {
  DataManagementStateFactory,
  type DataManagementStateRecord,
} from 'com.batch/data-management/models/data-management-state.record'
import { type FetchDataActions } from 'com.batch/data-management/usecases/fetch-data'
import {
  type UpdateAttributeSuccessAction,
  type UpdateDataActions,
  type UpdateEventSuccessAction,
} from 'com.batch/data-management/usecases/update-data'
import { type ConfirmDataActions } from 'com.batch/data-management/usecases/confirm-data'
import {
  CustomDataFactory,
  type CustomDataRecord,
} from 'com.batch/data-management/models/custom-data.record'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

const initialState = DataManagementStateFactory()

type DataManagementActions = FetchDataActions | UpdateDataActions | ConfirmDataActions

const itemReducer: (
  state: CustomDataRecord,
  action: UpdateAttributeSuccessAction | UpdateEventSuccessAction
) => CustomDataRecord = (state = CustomDataFactory(), action) => {
  const name =
    action.payload.attribute?.name ??
    action.payload.event?.name ??
    action.payload.tag?.name ??
    'unknow_name'
  if (state.name !== name) {
    return state
  }
  switch (action.type) {
    case 'UPDATE_ATTRIBUTE_SUCCESS':
    case 'UPDATE_EVENT_SUCCESS': {
      const data = action.payload.attribute ?? action.payload.event
      return state
        .set(
          'archivedAt',
          data?.isArchived !== undefined
            ? data?.isArchived
              ? new Date().toISOString()
              : null
            : state.archivedAt
        )
        .set(
          'overriddenName',
          data?.overriddenName !== undefined ? data?.overriddenName : state.overriddenName
        )
        .set(
          'overriddenType',
          action.payload.attribute?.overriddenType !== undefined
            ? action.payload.attribute?.overriddenType
            : state.overriddenType
        )
    }
    default:
      return state
  }
}

export function dataManagementReducer(
  state: DataManagementStateRecord = initialState,
  action: DataManagementActions
): DataManagementStateRecord {
  switch (action.type) {
    case 'SET_EDITING_ID':
      return state.set('editingId', action.payload)

    case 'FETCH_DATA_LIST':
      return state.set('loadingState', LoadingStatus.LOADING)

    case 'FETCH_DATA_LIST_SUCCESS':
      return state.set('loadingState', LoadingStatus.LOADED).set('entities', action.payload)

    case 'FETCH_DATA_LIST_FAILURE':
      return state.set('loadingState', LoadingStatus.ERROR)

    case 'UPDATE_EVENT_SUCCESS':
    case 'UPDATE_ATTRIBUTE_SUCCESS': {
      const entityKey = `${action.payload.attribute ? DataKind.ATTRIBUTE : DataKind.EVENT}_${
        action.payload.attribute?.name ??
        action.payload.event?.name ??
        action.payload.tag?.name ??
        'unknown_name'
      }`
      return state.setIn(
        ['entities', entityKey],
        itemReducer(state.entities.get(entityKey, CustomDataFactory()), action)
      )
    }

    case 'CONFIRM_DATA_SUCCESS':
      action.payload.forEach(data => {
        const entityKey = `${data.kind}_${data.name}`
        state = state.setIn(
          ['entities', entityKey],
          state.entities
            .get(entityKey, CustomDataFactory())
            .set('confirmedAt', new Date().toISOString())
        )
      })
      return state

    default:
      return state
  }
}
