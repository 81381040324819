import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'components/_hooks'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { ArchiveModal } from 'com.batch/data-management/ui/components/archive-modal/archive-modal'
import { ConfirmDataModal } from 'com.batch/data-management/ui/components/confirm-data-modal/confirm-data-modal'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'
import { DataManagementHeader } from 'com.batch/data-management/ui/components/data-management-header/data-management-header'
import { DataTab } from 'com.batch/data-management/ui/components/data-tab/data-tab'
import { dmLoadingStateSelector } from 'com.batch/data-management/store/data-management.selectors'
import { fetchData } from 'com.batch/data-management/usecases/fetch-data'
import { showToast } from 'com.batch.redux/toaster'
import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'
import { type DataManagementTab } from 'com.batch.common/router/url-generator-helper'
import { OverrideTypeModal } from 'com.batch/data-management/ui/components/override-type-modal/override-type-modal'

export const DataManagement = (): React.ReactNode => {
  const dispatch = useDispatch()
  const query = useQuery()

  const params = useParams<{ subtab: DataManagementTab }>()

  const loadingState = useSelector(dmLoadingStateSelector)

  const [dataToArchive, setDataToArchive] = React.useState<CustomDataRecord | null>(null)
  const [attributeToOverride, setAttributeToOverride] = React.useState<CustomDataRecord | null>(
    null
  )
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false)

  const isAttributesTab = React.useMemo(() => params.subtab === 'attributes', [params.subtab])
  const isArchivedShown = React.useMemo(() => query.has('archived'), [query])
  const searchTerms = React.useMemo(() => query.get('search'), [query])

  const toggleConfirmModal = React.useCallback(
    (isOpen: boolean) => () => {
      setIsConfirmModalOpen(isOpen)
    },
    []
  )

  const closeArchiveModal = React.useCallback(() => setDataToArchive(null), [])
  const closeOverrideModal = React.useCallback(() => setAttributeToOverride(null), [])

  const handleOnClickArchive = React.useCallback((data: CustomDataRecord) => {
    setDataToArchive(data)
  }, [])

  const handleOnClickOverrideType = React.useCallback((data: CustomDataRecord) => {
    setAttributeToOverride(data)
  }, [])

  React.useEffect(() => {
    dispatch(fetchData()).catch(e => dispatch(showToast({ message: e, kind: 'error' })))
  }, [dispatch])

  return (
    <React.Fragment>
      <DataManagementHeader
        isArchivedShown={isArchivedShown}
        isAttributesTab={isAttributesTab}
        loadingState={loadingState}
        search={searchTerms}
      />
      <DataTab
        kind={isAttributesTab ? DataKind.ATTRIBUTE : DataKind.EVENT}
        loadingState={loadingState}
        searchTerms={searchTerms}
        toggleConfirmModal={toggleConfirmModal}
        onClickArchive={handleOnClickArchive}
        onClickOverrideType={handleOnClickOverrideType}
        isArchivedShown={isArchivedShown}
      />
      <ConfirmDataModal
        isOpen={isConfirmModalOpen}
        onClose={toggleConfirmModal(false)}
        kind={isAttributesTab ? DataKind.ATTRIBUTE : DataKind.EVENT}
      />
      <OverrideTypeModal attribute={attributeToOverride} onClose={closeOverrideModal} />
      <ArchiveModal data={dataToArchive} onClose={closeArchiveModal} />
    </React.Fragment>
  )
}
