import styled from 'styled-components'
import { SwitchButton } from 'components/common/button'
import { SwitchButtonContainer } from 'components/common/button/button.styles'
import { colors } from 'components/styled/tokens'

export const FiltersWrapper = styled.div`
  display: flex;
  width: 280px;
  gap: 8px;
  margin-left: auto;
  > :first-child {
    position: absolute;
    z-index: 1;
  }
  > div:last-child {
    display: flex;
    gap: 8px;
    position: relative;
    margin-left: 110px;
    transition: opacity 0.2s ease-in-out;
  }
`

type ArchivedSwitchProps = {
  isActive: boolean
}

export const ArchivedSwitch = styled(SwitchButton)<ArchivedSwitchProps>`
  color: ${colors.textLight};
  ${props =>
    props.isActive &&
    `
        ${SwitchButtonContainer} {
        background: ${colors.textWarning};
      }`}
  i {
    margin-left: 8px;
  }
`
