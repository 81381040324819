import * as React from 'react'
import {
  TabContainer,
  TabCounter,
  TabNewCounter,
} from 'com.batch/shared/ui/component/tab/tab.styles'
import { Spinner } from 'com.batch/shared/ui/component/spinner'

type TabProps = {
  active?: boolean
  children: React.ReactNode
  counter?: number
  isLoading: boolean
  newCounter?: number
  to: string
  track?: string
}

export const Tab = ({
  active,
  children,
  counter,
  isLoading = true,
  newCounter,
  to,
  track,
}: TabProps): React.ReactNode => {
  return (
    <TabContainer $active={active} to={to} data-track={track}>
      {children}
      {isLoading && <Spinner />}
      {!isLoading && <TabCounter>{counter}</TabCounter>}
      {!isLoading && Boolean(newCounter) && <TabNewCounter>+{newCounter}</TabNewCounter>}
    </TabContainer>
  )
}
