import * as React from 'react'
import { dayjs } from 'com.batch.common/dayjs.custom'

import { Button } from 'components/common/button'
import { DataTableMoreOptions } from './data-table-more-options'
import { EventsTooltipWrapper, OverrideAsterisk } from './data-table-row.styles'
import { humanizeDayjs } from 'com.batch.common/utils'
import { Icon } from 'components/common/svg-icon'
import { Sources } from 'com.batch/data-management/ui/components/sources/sources'
import { TableCell, TableCellActions } from 'components/common/table'
import { Tooltip } from 'com.batch/shared/ui/component/tooltip'
import { findAttributTypeIcon } from 'com.batch/data-management/infra/formats/find-types-icon'
import { OverriddenNameIcon } from 'com.batch/data-management/ui/components/overriden-name-icon/overriden-name-icon'

import { useDispatch } from 'com.batch.common/react-redux'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'
import { showToast } from 'com.batch.redux/toaster'
import { confirmOneData } from 'com.batch/data-management/usecases/confirm-data'
import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'

import { colors } from 'components/styled/tokens'

type Props = {
  data: CustomDataRecord
  onClickOverrideType?: () => void
  onClickArchive: () => void
}

export const DataTableRowContent = React.memo(
  ({ data, onClickOverrideType, onClickArchive }: Props): React.ReactNode => {
    const {
      overriddenName,
      overriddenType,
      lastReceivedAt,
      lastReceivedType,
      platformLastReceivedAt,
      archivedAt,
      attributes,
      createdAt,
      name,
      kind,
    } = data
    const isNew = !data.confirmedAt
    const isArchived = Boolean(archivedAt)

    const [isLoading, setIsLoading] = React.useState(false)
    const dispatch = useDispatch()

    const status = React.useMemo(() => {
      if (isNew) return 'NEW'
      if (isArchived) return 'ARCHIVED'
      return 'ACTIVE'
    }, [isNew, isArchived])

    const typeIcon = React.useMemo(
      () =>
        isArchived
          ? 'archive'
          : kind === DataKind.EVENT
            ? 'trigger'
            : lastReceivedType && findAttributTypeIcon(overriddenType ?? lastReceivedType),
      [isArchived, kind, overriddenType, lastReceivedType]
    )

    const EventAttributesTooltip = React.useMemo(() => {
      return (
        <EventsTooltipWrapper>
          {attributes.map((a, i) => (
            <div key={i}>
              <Icon icon={findAttributTypeIcon(a.type)} style={{ marginRight: 8 }} /> {a.name}
            </div>
          ))}
        </EventsTooltipWrapper>
      )
    }, [attributes])

    const formatDate = React.useCallback((date: string | null) => {
      const currentDate = dayjs(date)
      return currentDate.isValid() ? humanizeDayjs({ date: currentDate, suffix: 'ago' }) : '–'
    }, [])

    const handleConfirmAttribute = React.useCallback(() => {
      if (isLoading) return
      setIsLoading(true)
      dispatch(confirmOneData(name, kind))
        .then(() => {
          dispatch(
            showToast({
              message: kind === DataKind.ATTRIBUTE ? 'Attribute confirmed' : 'Event confirmed',
              kind: 'success',
            })
          )
        })
        .catch(e => dispatch(showToast({ message: e, kind: 'error' })))
        .finally(() => setIsLoading(false))
    }, [dispatch, name, isLoading, kind])

    return (
      <React.Fragment>
        <TableCell>
          <Tooltip
            tooltip={
              overriddenType &&
              `This attribute type has been overridden to ${overriddenType.toLowerCase()}`
            }
            width={210}
            placement="bottom-start"
          >
            <div style={{ position: 'relative' }}>
              {typeIcon && (
                <Icon icon={typeIcon} className="attribute-type-icons" style={{ marginRight: 8 }} />
              )}
              {overriddenType && !isArchived && (
                <OverrideAsterisk className="override">
                  <Icon icon="required" className="override" />
                </OverrideAsterisk>
              )}
            </div>
          </Tooltip>

          {overriddenName && overriddenName.length > 0 ? overriddenName : name}
          {overriddenName && <OverriddenNameIcon name={name} />}
        </TableCell>

        {kind === DataKind.EVENT && (
          <TableCell style={attributes.size === 0 ? { color: colors.textDisabled } : {}}>
            {attributes.size === 0 ? (
              'None'
            ) : (
              <React.Fragment>
                {attributes.size}
                <Tooltip tooltip={EventAttributesTooltip} placement="bottom-start" padding={0}>
                  <Icon icon="documentation" className="override" style={{ marginLeft: 8 }} />
                </Tooltip>
              </React.Fragment>
            )}
          </TableCell>
        )}

        <TableCell>{formatDate(lastReceivedAt)}</TableCell>
        <TableCell>
          <Sources sources={platformLastReceivedAt} />
        </TableCell>
        <TableCell>{formatDate(createdAt)}</TableCell>
        <TableCellActions>
          {status === 'NEW' && (
            <Button onClick={handleConfirmAttribute} disabled={isLoading}>
              <Icon icon="check" />
            </Button>
          )}
          <DataTableMoreOptions
            name={name}
            kind={kind}
            isArchived={status === 'ARCHIVED'}
            onClickOverrideType={onClickOverrideType}
            onClickArchive={onClickArchive}
          />
        </TableCellActions>
      </React.Fragment>
    )
  }
)
