import Immutable from 'immutable'

import {
  type CustomDataRecord,
  CustomDataFactory,
} from 'com.batch/data-management/models/custom-data.record'
import {
  EventAttributeFactory,
  type EventAttributeRecord,
} from 'com.batch/data-management/models/event-attribute.record'
import { type CustomData } from 'com.batch/data-management/infra/dm-service.api'
import { type FetchDataSuccessReturned } from 'com.batch/data-management/usecases/fetch-data'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

const parseCustomData = (raw: CustomData, kind: DataKind, name: string): CustomDataRecord => {
  return CustomDataFactory({
    archivedAt: (raw.attribute ?? raw.event ?? raw.tag)?.archivedAt ?? null,
    attributes: raw.event?.attributes?.reduce(
      (acc: Immutable.List<EventAttributeRecord>, attr: EventAttributeRecord) =>
        acc.push(EventAttributeFactory({ name: attr.name, kind: attr.kind, type: attr.type })),
      Immutable.List<EventAttributeRecord>([])
    ),
    confirmedAt: (raw.attribute ?? raw.event ?? raw.tag)?.confirmedAt ?? null,
    createdAt: (raw.attribute ?? raw.event ?? raw.tag)?.createdAt ?? '',
    hasLabel: raw.event?.hasLabel ?? false,
    hasTag: raw.event?.hasTag ?? false,
    id: `${kind}_${name}`,
    kind: kind,
    lastReceivedAt: (raw.attribute ?? raw.event ?? raw.tag)?.lastReceivedAt ?? '',
    lastReceivedType: raw.tag ? 'ARRAY' : raw.attribute?.lastReceivedType ?? 'NULLVALUE',
    name,
    overriddenName: (raw.attribute ?? raw.event ?? raw.tag)?.overriddenName ?? null,
    overriddenType: raw.attribute?.overriddenType ?? null,
    updatedAt: (raw.attribute ?? raw.event ?? raw.tag)?.updatedAt ?? null,
    platformLastReceivedAt: raw.attribute?.platformLastReceivedAt
      ? Object.keys(raw.attribute.platformLastReceivedAt).reduce(
          (acc, key) =>
            acc.set(key.toLowerCase(), raw.attribute?.platformLastReceivedAt[key] ?? ''),
          Immutable.Map<string, string>()
        )
      : raw.event?.platformLastReceivedAt
        ? Object.keys(raw.event.platformLastReceivedAt).reduce(
            (acc, key) => acc.set(key.toLowerCase(), raw.event?.platformLastReceivedAt[key] ?? ''),
            Immutable.Map<string, string>()
          )
        : raw.tag?.platformLastReceivedAt
          ? Object.keys(raw.tag.platformLastReceivedAt).reduce(
              (acc, key) => acc.set(key.toLowerCase(), raw.tag?.platformLastReceivedAt[key] ?? ''),
              Immutable.Map<string, string>()
            )
          : Immutable.Map<string, string>(),
  })
}

export const parseCustomDatas = (raw: CustomData[]): FetchDataSuccessReturned => {
  return raw.reduce((acc, current) => {
    const kind = current.attribute
      ? DataKind.ATTRIBUTE
      : current.event
        ? DataKind.EVENT
        : current.tag
          ? DataKind.TAGS
          : DataKind.UNKNOWN

    const name = (current.attribute ?? current.event ?? current.tag)?.name ?? ''

    return acc.set(`${kind}_${name}`, parseCustomData(current, kind, name))
  }, Immutable.Map<string, CustomDataRecord>())
}
