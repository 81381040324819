import styled, { css } from 'styled-components'
import { colors } from 'components/styled/tokens'
import { TableRow, TableCell } from 'components/common/table'
import type React from 'react'

type AttributesTableRowProps = {
  $isArchived: boolean
  style?: React.CSSProperties
}
export const DataRow = styled(TableRow)<AttributesTableRowProps>`
  ${props =>
    props.$isArchived &&
    css`
      position: relative;
      background-image: url('/medias/img/patterns/stripe-patterns.svg');
      background-size: 1%;

      &:last-of-type {
        &:before {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 3%,
          rgba(255, 255, 255, 0) 97%,
          rgba(255, 255, 255, 1) 100%
        );
        z-index: 0;
      }

      ${TableCell} {
        z-index: 0;
        color: ${colors.textDisabled};

        .attribute-type-icons {
          color: ${colors.textWarning};
        }
      }
    `}
`

export const EditingRow = styled.div`
  grid-column: 1 / 7;
  display: grid;
  padding: 0 12px;

  grid-template-columns: minmax(100px, 1fr) auto auto;

  & > button:first-of-type {
    margin: 0 7px 0 12px;
  }
`

export const OverrideAsterisk = styled.div`
  position: absolute;
  color: ${colors.textWarning};
  top: -5px;
  right: 3px;
  background-color: white;
  width: 11px;
  height: 11px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 12px;
`

export const EventsTooltipWrapper = styled.div`
  text-align: left;
  padding: 0 8px;
  width: 184px;

  & > div {
    padding: 8px 0;
    border-bottom: 1px solid ${colors.strokeContrast};

    &:last-of-type {
      border-bottom: 0;
    }
  }
`
