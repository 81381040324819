import styled from 'styled-components'
import { Box, BoxBody, BoxHeader, BoxFooter } from 'components/common/box'

export const DataModalBox = styled(Box)`
  width: 380px;

  ${BoxHeader} {
    padding-right: 8px;
    border: none;
  }

  ${BoxBody} {
    padding: 0 28px 20px 20px;
    border: none;

    & > p {
      margin-bottom: 7px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  ${BoxFooter} {
    justify-content: space-between;
    border: none;
    padding: 8px;
    height: fit-content;
  }
`
