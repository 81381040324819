import styled from 'styled-components'
import { colors } from 'components/styled/tokens'

export const SourcesWrapper = styled.div`
  display: flex;
  width: 100px;
  height: 24px;
  padding: 3px 0px 1px 1px;
  box-shadow: 0 0 0 1px ${colors.stroke} !important;
  border-radius: 24px;
  color: ${colors.textLight};

  & > div {
    width: 24px;
    display: flex;
    justify-content: center;
  }
`

export const Dot = styled.div`
  height: 3px;
  width: 3px;
  align-self: center;
  background-color: ${colors.stroke};
  border-radius: 3px;
`

export const SourcesTooltip = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 8px;
  padding: 8px 0;
  text-align: left;
  border-bottom: 1px solid ${colors.strokeContrast};
  color: red;

  &:last-of-type {
    border-bottom: 0;
  }

  & > div {
    display: flex;
  }

  & > div:first-of-type,
  & > div:first-of-type i {
    margin-right: 8px;
    color: ${colors.textContrast};
  }

  & > div:last-of-type {
    color: ${colors.textContrastLight};
  }
`
