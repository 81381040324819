import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'

export type FetchDataSuccessReturned = Immutable.Map<string, CustomDataRecord>

type FetchDataAction = ReduxAction<'FETCH_DATA_LIST', null>
type FetchDataSuccessAction = ReduxAction<'FETCH_DATA_LIST_SUCCESS', FetchDataSuccessReturned>
type FetchDataFailureAction = ReduxAction<'FETCH_DATA_LIST_FAILURE', string>

export type FetchDataActions = FetchDataAction | FetchDataSuccessAction | FetchDataFailureAction

export const fetchData = (): DispatchExtraBoundFn<Promise<FetchDataSuccessReturned>> => {
  return async (dispatch, getState, { dmService }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: dmService.fetchList({ projectKey: project.projectKey }),
      actionName: 'FETCH_DATA_LIST',
    })
  }
}
