import { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'
import { useLocation } from 'react-router-dom'

import { useProjectChannels } from 'components/project/use-project-channels'

import { generateUrl } from 'com.batch.common/router/router'
import { ucFirst } from 'com.batch.common/utils'

import { type NavItemId, type ChannelInUrl, type NavItem } from './router-types'
import {
  coerceSettingsTab,
  coerceProfilesTab,
  generateAnalyticsUrl,
  generateOrchestrationListUrl,
  generateProfilebaseUrl,
  generateSettingsUrl,
  generateProfilesUrl,
  generateDataManagementUrl,
} from './url-generator-helper'

import { type AppRecord } from 'com.batch.redux/_records'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type availableIcons } from 'components/common/svg-icon'

const buildNav = (
  urls: {
    analytics: string
    userbaseOrProfiles: string
    campaigns: string
    automations: string
    settings: string
  },
  pathname: string,
  isCep: boolean
): Array<NavItem> => {
  const profilesOrUserbaseTab: {
    id: NavItemId
    label: string
    icon: availableIcons
    url: string
    isActive: boolean
  } = isCep
    ? {
        id: 'profiles',
        label: 'Profiles',
        icon: 'user' as const,
        url: urls.userbaseOrProfiles,
        isActive: pathname.includes('/profiles'),
      }
    : {
        id: 'userbase',
        label: 'Userbase',
        icon: 'user' as const,
        url: urls.userbaseOrProfiles,
        isActive: pathname.includes('/userbase'),
      }
  return [
    {
      id: 'analytics',
      label: 'Analytics',
      icon: 'analytics' as const,
      url: urls.analytics,
      isActive:
        pathname.startsWith(urls.analytics) ||
        /^\/\d+\/projects\/[A-Za-z0-9]+\/analytics\/(?:email|push|sms)$/.test(pathname) ||
        /^\/\d+\/projects\/[A-Za-z0-9]+\/apps\/\d+\/analytics(?:\/[A-Za-z0-9]+)*$/.test(pathname),
    },
    profilesOrUserbaseTab,
    {
      id: 'campaigns',
      label: 'Campaigns',
      icon: 'message' as const,
      url: urls.campaigns,
      isActive: pathname.includes('/campaigns'),
    },
    {
      id: 'automations',
      label: 'Automations',
      icon: 'automation' as const,
      url: urls.automations,
      isActive: pathname.includes('/automations'),
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: 'settings' as const,
      url: urls.settings,
      /*
        we can't use starts with because projects email settings are a edge case.
        if one day we get an other URL with an /settings/ path in it, we'll do someting more complicated
        to check if active or not
      */
      isActive: pathname.includes('/settings/'),
    },
  ]
}
export const buildLegacyNav = (pathname: string, app: AppRecord): Array<NavItem> => {
  const analytics = generateAnalyticsUrl({
    companyId: app.companyId ?? 0,
    appId: app.id,
  })
  const userbase = generateUrl('analytics_userbase', {
    companyId: app.companyId ?? 0,
    appId: app.id,
  })
  const campaigns = generateOrchestrationListUrl({
    companyId: app.companyId ?? 0,
    channel: { appId: app.id },
    scheduling: 'campaigns',
  })
  const automations = generateOrchestrationListUrl({
    companyId: app.companyId ?? 0,
    channel: { appId: app.id },
    scheduling: 'automations',
  })
  const settings = generateSettingsUrl({
    companyId: app.companyId ?? 0,
    channel: { appId: app.id },
    activeTab: 'global',
  })
  return buildNav(
    {
      campaigns,
      userbaseOrProfiles: userbase,
      analytics,
      automations,
      settings,
    },
    pathname,
    false
  )
}

export const useBuildLegacyNav = (app: AppRecord): Array<NavItem> => {
  const { pathname } = useLocation()
  return buildLegacyNav(pathname, app)
}

export const buildProjectNav = ({
  pathname,
  project,
  channel,
  hasPushChannel,
  hasOneCepChannelConfigured,
}: {
  project: ProjectRecord
  pathname: string
  channel: ChannelInUrl
  hasPushChannel: boolean
  hasOneCepChannelConfigured: boolean
}): Array<NavItem> => {
  const fallbackAppId: number = project.appIds.first()
  const appId: number =
    channel === 'email' || channel === 'push' || channel === 'sms' || channel === null
      ? fallbackAppId
      : channel.appId
  const firstChannel = project.emailConfigured
    ? 'email'
    : project.anyPushConfigured
      ? 'push'
      : 'sms'
  const analytics = hasOneCepChannelConfigured
    ? generateUrl('cross_orchestrations_analytics', {
        companyId: project.companyId,
        projectId: project.id,
        channel: firstChannel,
      })
    : generateAnalyticsUrl({
        companyId: project.companyId,
        appId,
        projectId: project.id,
      })
  const profiles = hasOneCepChannelConfigured
    ? generateProfilebaseUrl({
        companyId: project.companyId,
        projectId: project.id,
      })
    : generateProfilesUrl({
        companyId: project.companyId,
        projectId: project.id,
        ...(hasPushChannel ? { appId } : { activeTab: 'audiences' }),
      })
  const campaigns = generateOrchestrationListUrl({
    companyId: project.companyId,
    channel: hasOneCepChannelConfigured ? null : { appId },
    projectId: project.id,
    scheduling: 'campaigns',
  })
  const automations = generateOrchestrationListUrl({
    companyId: project.companyId,
    channel: hasOneCepChannelConfigured ? null : { appId },
    projectId: project.id,
    scheduling: 'automations',
  })
  const settings = generateSettingsUrl({
    companyId: project.companyId,
    channel: { appId },
    projectId: project.id,
    activeTab: 'global',
  })
  return buildNav(
    {
      campaigns,
      userbaseOrProfiles: profiles,
      analytics,
      automations,
      settings,
    },
    pathname,
    true
  )
}

export const useBuildProjectNav = ({
  project,
  channel,
}: {
  project: ProjectRecord
  channel: ChannelInUrl
}): Array<NavItem> => {
  const { pathname } = useLocation()
  const apps = useSelector(currentProjectAppsSelector)
  const hasPushChannel = React.useMemo(() => {
    return apps.some(app => ['webpush', 'ios', 'android'].includes(app.platform))
  }, [apps])

  const { hasOneCepChannelConfigured } = useProjectChannels()
  return buildProjectNav({ pathname, project, channel, hasPushChannel, hasOneCepChannelConfigured })
}

/*
  to switch project, we need to find the current active item in the main nav
  then we need to replace projectId, and when appropriate to replace appId
*/
export const useProjectSwitcher: (arg1: {
  project: ProjectRecord
  channel: ChannelInUrl
}) => (arg1: ProjectRecord) => string = ({ project, channel }) => {
  const navData = useBuildProjectNav({ project, channel })
  return (targetProject: ProjectRecord) => {
    const matchingLocation: string = navData.reduce((acc, item) => {
      if (item.isActive) return item.url
      return acc
    }, navData[0].url)
    const targetHasNoCepChannel =
      !targetProject.emailConfigured &&
      !targetProject.anyPushConfigured &&
      !targetProject.smsConfigured
    return (
      matchingLocation
        .replace(project.id, targetProject.id)
        .replace(
          `/${
            channel === 'email' || channel === 'push' || channel === null || channel === 'sms'
              ? 'no app in url'
              : `apps/${channel.appId}`
          }/`,
          `/apps/${targetProject.appIds.first()?.toString() ?? '0'}/`
        )
        // if we still link to an app from current project, replace with target (for settings/email hack)
        .replace(
          `apps/${project.appIds.first()?.toString() ?? '0'}`,
          `apps/${targetProject.appIds.first()?.toString() ?? '0'}`
        )
        // we need to handle apps & CEP channels differences on switch for analytics
        .replace(
          /analytics\/(?:email|push|sms)$/,
          targetHasNoCepChannel
            ? `apps/${targetProject.appIds.first()?.toString() ?? '0'}/analytics`
            : 'analytics'
        )
    )
  }
}

/*
  to switch app, we need to find the current active item in the main nav
  then we need to replace appId
*/
export const useAppSwitcher: (app: AppRecord) => (arg1: number) => string = app => {
  const navData = useBuildLegacyNav(app)
  return (targetAppId: number) => {
    const matchingLocation: string = navData.reduce((acc, item) => {
      if (item.isActive) return item.url
      return acc
    }, navData[0].url)
    return matchingLocation.replace(`/apps/${app.id}/`, `/apps/${targetAppId}/`)
  }
}

export const useBuildChannelNav: (arg1: {
  channel: ChannelInUrl
  apps: List<AppRecord>
  project: ProjectRecord
  hasCepCampaignFeature: boolean
  // DATA MANAGEMENT SHORTCUT (to remove when data management will be released whithout shortcut)
  isShortcutActive: boolean
}) => Array<NavItem> = ({ channel, project, apps, hasCepCampaignFeature, isShortcutActive }) => {
  const { pathname } = useLocation()
  const settingsTab = coerceSettingsTab(pathname)
  const profilesTab = coerceProfilesTab(pathname.split('/').pop() ?? '')
  const navData = useBuildProjectNav({ project, channel })
  const targetNavItem: NavItem = navData.find(item => item.isActive) ?? navData[0]
  const projectHasOneChannelConfigured =
    project.smsConfigured || project.anyPushConfigured || project.emailConfigured
  const appsItems: NavItem[] = apps
    .toArray()
    .filter(app => app.platform !== 'webpush' || settingsTab !== 'themes')
    .map(app => {
      let url = ''
      switch (targetNavItem.id) {
        case 'analytics':
          url = generateAnalyticsUrl({
            companyId: project.companyId,
            appId: app.id,
            projectId: project.id,
          })
          break
        case 'userbase':
          url = generateUrl('analytics_userbase', {
            companyId: project.companyId,
            appId: app.id,
          })
          break
        case 'profiles':
          url = generateProfilesUrl({
            companyId: project.companyId,
            appId: app.id,
            projectId: project.id,
            activeTab: profilesTab,
          })
          break
        case 'campaigns':
          url = generateOrchestrationListUrl({
            companyId: project.companyId,
            channel: { appId: app.id },
            projectId: project.id,
            scheduling: 'campaigns',
          })
          break
        case 'automations':
          url = generateOrchestrationListUrl({
            companyId: project.companyId,
            channel: { appId: app.id },
            projectId: project.id,
            scheduling: 'automations',
          })
          break
        case 'settings':
          url = generateSettingsUrl({
            companyId: project.companyId,
            channel: { appId: app.id },
            projectId: project.id,
            activeTab: settingsTab === 'email' ? 'push' : settingsTab,
          })
      }

      return {
        id: targetNavItem.id,
        className: app.platform,
        label: getAppNavButtonLabel(false, app),
        icon: (app.platform !== '' ? app.platform : 'webpush') as Maybe<availableIcons>,
        url,
        isActive:
          channel !== 'email' &&
          channel !== 'push' &&
          channel !== 'sms' &&
          channel !== null &&
          channel.appId === app.id,
      } as NavItem
    })

  if (projectHasOneChannelConfigured && targetNavItem.id === 'analytics') {
    const analyticsItems: Array<NavItem> = []
    if (project.emailConfigured)
      analyticsItems.push({
        id: targetNavItem.id,
        label: 'Email',
        icon: 'mail' as availableIcons,
        url: generateUrl('cross_orchestrations_analytics', {
          companyId: project.companyId,
          channel: 'email',
          projectId: project.id,
        }),
        isActive: channel === 'email',
        className: 'email',
      })
    if (project.anyPushConfigured)
      analyticsItems.push({
        id: targetNavItem.id,
        label: 'Push',
        icon: 'push' as availableIcons,
        url: generateUrl('cross_orchestrations_analytics', {
          companyId: project.companyId,
          channel: 'push',
          projectId: project.id,
        }),
        isActive: channel === 'push',
        className: 'push',
      })
    if (project.smsConfigured)
      analyticsItems.push({
        id: targetNavItem.id,
        label: 'SMS',
        icon: 'sms' as availableIcons,
        url: generateUrl('cross_orchestrations_analytics', {
          companyId: project.companyId,
          channel: 'sms',
          projectId: project.id,
        }),
        isActive: channel === 'sms',
        className: 'sms',
      })

    return analyticsItems
  }

  if (
    projectHasOneChannelConfigured &&
    targetNavItem.id === 'profiles' &&
    (profilesTab === 'audiences' ||
      profilesTab === '' ||
      // DATA MANAGEMENT SHORTCUT (to remove when data management will be released whithout shortcut)
      (profilesTab === 'data-management' && isShortcutActive))
  ) {
    const url =
      // DATA MANAGEMENT SHORTCUT (to remove when data management will be released whithout shortcut)
      profilesTab === 'data-management' && isShortcutActive
        ? generateDataManagementUrl({
            tab: 'attributes',
          })
        : generateProfilesUrl({
            companyId: project.companyId,
            projectId: project.id,
            activeTab: profilesTab,
          })
    const overviewItem: NavItem = {
      id: targetNavItem.id,
      label: 'Omnichannel',
      url,
      isActive: isOmnichannelPath(channel),
      isIndex: true,
    }
    return [overviewItem, ...appsItems]
  }

  if (
    projectHasOneChannelConfigured &&
    ((targetNavItem.id === 'campaigns' && hasCepCampaignFeature) ||
      targetNavItem.id === 'automations')
  ) {
    const url = generateOrchestrationListUrl({
      companyId: project.companyId,
      channel: 'email',
      projectId: project.id,
      scheduling: targetNavItem.id === 'automations' ? 'automations' : 'campaigns',
    })

    const omnichannelNavItem: NavItem = {
      id: targetNavItem.id,
      label: 'Omnichannel',
      url,
      isActive: isOmnichannelPath(channel),
      isIndex: true,
    }
    return [omnichannelNavItem, ...appsItems]
  }
  /*
    today we got only one project only settings, email
  */

  const omnichannelTabs = ['push', 'email', 'labels', 'cappings']

  if (
    projectHasOneChannelConfigured &&
    targetNavItem.id === 'settings' &&
    omnichannelTabs.includes(settingsTab)
  ) {
    const isOmnichannel = ['labels', 'cappings'].includes(settingsTab)
    const label = isOmnichannel ? 'Omnichannel' : 'Email'
    const icon = isOmnichannel ? undefined : 'mail-fill'

    const omniOrEmailLink: NavItem = {
      id: 'settings',
      label,
      icon,
      isIndex: isOmnichannel,
      url: generateSettingsUrl({
        companyId: project.companyId,
        channel: 'email',
        projectId: project.id,
        activeTab: settingsTab === 'push' ? 'email' : settingsTab,
      }),
      className: isOmnichannel ? 'omni' : 'email',
      isActive: channel === null || channel === 'email',
    }
    return isOmnichannel ? [omniOrEmailLink, ...appsItems] : [...appsItems, omniOrEmailLink]
  }

  return appsItems
}

const isOmnichannelPath = (channel: ChannelInUrl) => {
  return (
    channel === 'email' ||
    channel === 'sms' ||
    channel === 'push' ||
    channel === null ||
    channel.appId === 0
  )
}

const getAppNavButtonLabel = (isChannelsSettings: boolean, app: AppRecord): string => {
  switch (app.platform) {
    case 'ios':
      return isChannelsSettings ? 'Push iOS' : 'iOS'
    case 'android':
      return isChannelsSettings ? 'Push Android' : ucFirst(app.platform)
    case 'webpush':
      return isChannelsSettings ? 'Push Web' : 'Website'
    default:
      return ucFirst(app.platform)
  }
}
