import { type List } from 'immutable'
import * as React from 'react'

import {
  useCurrentCompanyHasFeature,
  useCurrentCompanyHasOneFeatureAmongst,
} from 'components/_hooks/use-company-feature'
import { ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Separator } from 'components/project/navigation/navbar.styles'

import { useBuildChannelNav, type ChannelInUrl } from 'com.batch.common/router'

import { ChannelSubNavContainer } from './channel-sub-nav.styles'

import { type AppRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { AppsAnalyticsDropdown } from 'components/navigation/apps-analytics-dropdown'
import { useMatchRoutes } from 'components/navigation/use-match-routes'

type ChannelSubNavProps = {
  apps: List<AppRecord>
  project: ProjectRecord
  channel: ChannelInUrl
  isTransparent?: boolean
  isCollapsed?: boolean
  gotBorder?: boolean
}

export const ChannelSubNav = ({
  apps,
  project,
  channel,
  isTransparent = false,
  isCollapsed = false,
  gotBorder = false,
}: ChannelSubNavProps): React.ReactElement | null => {
  const hasCepCampaignFeature = useCurrentCompanyHasOneFeatureAmongst([
    'email-campaign',
    'cep-sms-campaign',
    'cep-push-campaign',
  ])

  const { isAnalytics, isDataManagement } = useMatchRoutes()

  // DATA MANAGEMENT SHORTCUT (to remove when data management will be released whithout shortcut)
  const [isShortcutActive, setIsShortcutActive] = React.useState(isDataManagement)
  const handleShorcutEvent = React.useCallback(
    e => {
      if (e.metaKey && e.shiftKey && e.code === 'KeyU') setIsShortcutActive(!isShortcutActive)
    },
    [isShortcutActive]
  )
  React.useEffect(() => {
    document.addEventListener('keydown', handleShorcutEvent)
    return () => document.removeEventListener('keydown', handleShorcutEvent)
  }, [handleShorcutEvent])
  // END SHORTCUT

  const navItems = useBuildChannelNav({
    apps,
    project,
    channel,
    hasCepCampaignFeature,
    // DATA MANAGEMENT SHORTCUT (to remove when data management will be released whithout shortcut)
    isShortcutActive,
  })
  // we need a hack to hide website on CEP automations pages with only inapp-campaigns
  const hasInapp = useCurrentCompanyHasFeature('inapp-campaigns')
  const hasLegacyPushOnAutomation = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
  ])
  const hideWeb = hasInapp && !hasLegacyPushOnAutomation

  const hasAtLeastOneCepChannel = React.useMemo(
    () => project.emailConfigured || project.smsConfigured || project.anyPushConfigured,
    [project.emailConfigured, project.smsConfigured, project.anyPushConfigured]
  )
  // we hide subnav on form pages
  if (navItems.length === 0) return null

  return (
    <ChannelSubNavContainer
      isTransparent={isTransparent}
      isCollapsed={isCollapsed}
      gotBorder={gotBorder}
      data-testid="channel-subnav"
    >
      {navItems
        .filter(nav => nav.id !== 'automations' || !(hideWeb && nav.className === 'webpush'))
        .map((item, key) => (
          <React.Fragment key={key}>
            <ButtonLink
              href={item.url}
              isActive={item.isActive}
              kind="inline"
              addOn={item.icon ? 'suffix' : undefined}
              className={(item.className ?? '') + (item.isActive ? ' isActive' : '')}
              data-testid={`channel-subnav-${item.className ?? 'omni'}`}
            >
              {item.icon && <Icon icon={item.icon} />}
              {item.label}
            </ButtonLink>
            {item.isIndex && navItems.length > 1 && <Separator />}
          </React.Fragment>
        ))}
      {isAnalytics && hasAtLeastOneCepChannel && <AppsAnalyticsDropdown apps={apps} />}
    </ChannelSubNavContainer>
  )
}
