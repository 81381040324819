import { type TypeValue } from 'com.batch/profile/infra/debug/models/shared.model'

export enum EventAttributeKind {
  PRIMITIVE = 'PRIMITIVE',
  ARRAY = 'ARRAY',
  OBJECT = 'OBJECT',
}

export type EventAttribute = {
  name: string
  type: TypeValue
  kind: EventAttributeKind
}
