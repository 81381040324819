import { get as _get } from 'lodash'
import * as React from 'react'
import { Route, Routes } from 'react-router-dom'

import { GDPR } from 'components/account/company/gdpr'
import { CompanySettings } from 'components/account/company/settings'
import { Security } from 'components/account/security'
import { Settings } from 'components/account/settings'
import { Team } from 'components/account/team/team'
import { Analytics } from 'components/analytics/analytics'
import { AnalyticsMenu } from 'components/analytics/analytics-menu'
import { Notification } from 'components/analytics/notification'
import { Reach } from 'components/analytics/reach'
import { AppSettingsWrapper } from 'components/app/app-settings-wrapper'
import { CreateAppRoute } from 'components/app/create/create-app-route'
import Integrate from 'components/app/integrate/integrate'
import { Login } from 'components/authentication/login/login'
import { Login2FA } from 'components/authentication/login/login-2fa'
import { LoginSSORefresh } from 'components/authentication/login/login-sso-refresh'
import { NewPassword } from 'components/authentication/login/new-password'
import { PasswordRecovery } from 'components/authentication/login/password-recovery'
import { Register2FA } from 'components/authentication/register/register-2fa'
import { RegisterCompany } from 'components/authentication/register/register-company'
import { RegisterInfos } from 'components/authentication/register/register-infos'
import { RegisterNext } from 'components/authentication/register/register-next'
import { RegisterPassword } from 'components/authentication/register/register-password'
import { CampaignList } from 'components/campaign/campaign-list'
import { FiltersProvider } from 'components/campaign/filters/filters-provider'
import { LegacyCampaignForm } from 'components/campaign/form/form-legacy'
import { ReviewScreen } from 'components/campaign/review/review-screen'
import { AppWrapper } from 'components/common/app-wrapper'
import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'
import { DataWrapper } from 'components/data/data-wrapper'
import { Userbase } from 'components/data/userbase'
import { ProjectRouter } from 'components/project/project-router'
import { ProjectRouterWrapper } from 'components/project/project-router-wrapper'
import { Content } from 'components/styled/blocs'

import { NavInserter } from './nav-inserter'

import { BillingPage } from 'com.batch/account/ui/pages/billing'
import { Profilebase } from 'com.batch/profilebase/ui/pages/profilebase'
import { type onboardingStepType } from 'com.batch.redux/user.records'
import { Intercom } from 'com.batch/shared/ui/intercom'
// get default data set in dashboard.html.twig
const email: string = _get(window, 'user.email', '')
const invitationEmail = _get(window, 'invitationEmail', '')
const isInvite: boolean =
  !!invitationEmail ||
  _get(window, 'user.onboardingCompletedSteps', [] as Array<onboardingStepType>).indexOf(
    'company'
  ) !== -1
const enforce2Fa = _get(
  window,
  'user.hasCompanyWithTwoFactorAuthEnforcement',
  _get(window, 'invitationCompany.enforce2FA', false)
)

export const DashboardRouter = (): React.ReactElement => {
  return (
    <AppWrapper sidebarKind="account">
      <NavInserter />
      <CreateAppRoute />
      <Integrate />
      <Intercom />

      <Routes>
        <Route
          path="/register/join/:token"
          element={
            <PageTracker category={trackingCategories.register} name={trackingPages.invite}>
              <RegisterInfos forcedEmail={invitationEmail} />
            </PageTracker>
          }
        />
        <Route
          path="/register/sso"
          element={
            <PageTracker category={trackingCategories.register} name={trackingPages.invite}>
              <RegisterInfos forcedEmail={email} sso />
            </PageTracker>
          }
        />
        <Route
          path="/register"
          element={
            <PageTracker category={trackingCategories.register} name={trackingPages.register}>
              <RegisterInfos forcedEmail="" />
            </PageTracker>
          }
        />
        <Route
          path="/register/password"
          element={
            <PageTracker category={trackingCategories.register} name={trackingPages.password}>
              <RegisterPassword isInvite={isInvite} email={email} />
            </PageTracker>
          }
        />
        <Route
          path="/register/congrats"
          element={
            <PageTracker category={trackingCategories.register} name={trackingPages.congrats}>
              <RegisterNext isInvite={isInvite} />
            </PageTracker>
          }
        />
        <Route
          path="/register/2fa"
          element={
            <PageTracker category={trackingCategories.register} name={trackingPages['2FA']}>
              <Register2FA enforce2Fa={enforce2Fa} isInvite={isInvite} />
            </PageTracker>
          }
        />
        <Route
          path="/register/company"
          element={
            <PageTracker category={trackingCategories.register} name={trackingPages.company}>
              <RegisterCompany isInvite={isInvite} />
            </PageTracker>
          }
        />
        <Route
          path="/login/2fa"
          element={
            <PageTracker category={trackingCategories.login} name={trackingPages.login2FA}>
              <Login2FA />
            </PageTracker>
          }
        />
        <Route
          path="/login/password"
          element={
            <PageTracker category={trackingCategories.login} name={trackingPages.login}>
              <Login isFullForm />
            </PageTracker>
          }
        />
        <Route
          path="/login"
          element={
            <PageTracker category={trackingCategories.login} name={trackingPages.login}>
              <Login />
            </PageTracker>
          }
        />
        <Route
          path="/password-reset"
          element={
            <PageTracker category={trackingCategories.login} name={trackingPages.resetRequest}>
              <PasswordRecovery />
            </PageTracker>
          }
        />
        <Route
          path="/password-reset/change"
          element={
            <PageTracker category={trackingCategories.login} name={trackingPages.reset}>
              <NewPassword
                token={window.recoveryPasswordToken}
                email={window.recoveryPasswordForEmail}
              />
            </PageTracker>
          }
        />
        {/* if the window.ssoEmail is defined its means we should refresh the session */}
        {window.ssoEmail !== undefined && (
          <Route
            path="/"
            element={
              <PageTracker category={trackingCategories.login} name={trackingPages.login}>
                <h2>CPCPCP</h2>
                <LoginSSORefresh email={window.ssoEmail} />
              </PageTracker>
            }
          />
        )}
        {/* ============ PROJECT ONLY ROUTES ================= */}
        <Route path="*" element={<ProjectRouter />} />
        {/* ============ ACCOUNT ================= */}
      </Routes>
      <Content noPadding>
        <Routes>
          <Route
            path="/account"
            element={
              <PageTracker category={trackingCategories.account} name={trackingPages.settings}>
                <Settings />
              </PageTracker>
            }
          />
          <Route
            path="/account/security"
            element={
              <PageTracker category={trackingCategories.account} name={trackingPages.security}>
                <Security />
              </PageTracker>
            }
          />
          <Route
            path={'/:companyId/billing'}
            element={
              <PageTracker
                category={trackingCategories.accountCompany}
                name={trackingPages.billing}
              >
                <BillingPage />
              </PageTracker>
            }
          />
          <Route
            path={'/:companyId/users'}
            element={
              <PageTracker category={trackingCategories.accountCompany} name={trackingPages.team}>
                <Team />
              </PageTracker>
            }
          />
          <Route
            path={'/:companyId/users/new'}
            element={
              <PageTracker category={trackingCategories.accountCompany} name={trackingPages.team}>
                <Team inviteOpened />
              </PageTracker>
            }
          />
          <Route path={'/:companyId/users/:userId'} element={<Team />} />
          <Route
            path={'/:companyId/settings'}
            element={
              <PageTracker
                category={trackingCategories.accountCompany}
                name={trackingPages.companySettings}
              >
                <CompanySettings />
              </PageTracker>
            }
          />
          <Route
            path={'/:companyId/gdpr'}
            element={
              <PageTracker
                category={trackingCategories.accountCompany}
                name={trackingPages.privacy}
              >
                <GDPR />
              </PageTracker>
            }
          />
          <Route path="*" element={null} />
        </Routes>
      </Content>
      <ProjectRouterWrapper>
        {/* ============ ANALYTICS ================= */}
        <Route
          path="apps/:appId/analytics"
          element={
            <PageTracker category={trackingCategories.data} name={trackingPages.audience}>
              <Content>
                <AnalyticsMenu />
                <Analytics />
              </Content>
            </PageTracker>
          }
        />
        <Route
          path="apps/:appId/analytics/reach"
          element={
            <PageTracker category={trackingCategories.data} name={trackingPages.reach}>
              <Content>
                <AnalyticsMenu />
                <Reach />
              </Content>
            </PageTracker>
          }
        />
        <Route
          path="apps/:appId/analytics/push/:pushAnalyticsTab?"
          element={
            <PageTracker category={trackingCategories.data} name={trackingPages.pushOverview}>
              <Content>
                <AnalyticsMenu />
                <Notification />
              </Content>
            </PageTracker>
          }
        />

        {/* ============ PROFILEBASE ================= */}
        <Route
          path="profiles"
          element={
            <PageTracker category={trackingCategories.data} name={trackingPages.profilebase}>
              <Content>
                <Profilebase />
              </Content>
            </PageTracker>
          }
        />

        {/* ============ USERBASE ================= */}
        <Route
          path="apps/:appId/userbase/*"
          element={
            <PageTracker category={trackingCategories.data} name={trackingPages.userbase}>
              <Content>
                <Userbase />
              </Content>
            </PageTracker>
          }
        />
        {/* ============ CAMPAIGNS ================= */}
        <Route
          path="apps/:appId/:schedulingType/:campaignType?/:token/review"
          element={<ReviewScreen tab="review" />}
        />
        <Route
          path="apps/:appId/:schedulingType/:campaignType?/:token/analytics"
          element={<ReviewScreen tab="analytics" />}
        />
        <Route
          path="apps/:appId/:schedulingType/:campaignType?/:token"
          element={<LegacyCampaignForm />}
        />
        <Route
          path="apps/:appId/:schedulingType"
          element={
            <FiltersProvider>
              <CampaignList />
            </FiltersProvider>
          }
        />

        {/* ============ APP ================= */}

        <Route
          path="apps/:appId/settings/privacy/new"
          element={<AppSettingsWrapper setting="privacy/new" />}
        />
        <Route
          path="apps/:appId/settings/profiles"
          element={<AppSettingsWrapper setting="profiles" />}
        />
        <Route
          path="apps/:appId/settings/privacy"
          element={<AppSettingsWrapper setting="privacy" />}
        />
        <Route
          path="apps/:appId/settings/cappings"
          element={<AppSettingsWrapper setting="cappings" />}
        />
        <Route
          path="apps/:appId/settings/labels"
          element={<AppSettingsWrapper setting="labels" />}
        />
        <Route
          path="apps/:appId/settings/team/edit"
          element={<AppSettingsWrapper setting="team/edit" />}
        />
        <Route path="apps/:appId/settings/team" element={<AppSettingsWrapper setting="team" />} />
        <Route
          path="apps/:appId/settings/custom-data"
          element={<AppSettingsWrapper setting="custom-data" />}
        />
        <Route
          path="apps/:appId/settings/audiences"
          element={<AppSettingsWrapper setting="audiences" />}
        />
        <Route
          path="apps/:appId/settings/themes/new"
          element={<AppSettingsWrapper setting="themes/new" />}
        />
        <Route
          path="apps/:appId/settings/themes/:themeId"
          element={<AppSettingsWrapper setting="themes/:themeId" />}
        />
        <Route
          path="apps/:appId/settings/themes"
          element={<AppSettingsWrapper setting="themes" />}
        />
        <Route path="apps/:appId/settings/push" element={<AppSettingsWrapper setting="push" />} />
        <Route
          path="apps/:appId/settings/debug/transactional"
          element={<AppSettingsWrapper setting="debug/transactional" />}
        />
        <Route path="apps/:appId/settings/debug" element={<AppSettingsWrapper setting="debug" />} />
        <Route
          path="apps/:appId/settings/global"
          element={<AppSettingsWrapper setting="general" />}
        />

        {/* Profiles routes */}
        <Route path="profiles/:tab/*" element={<DataWrapper />}>
          <Route path=":subtab" element={<DataWrapper />} />
        </Route>
        <Route path="apps/:appId/profiles/*" element={<DataWrapper />}>
          <Route path=":tab" element={<DataWrapper />}>
            <Route path=":action" element={<DataWrapper />} />
          </Route>
        </Route>

        <Route path="*" element={null} />
      </ProjectRouterWrapper>
    </AppWrapper>
  )
}
