import Immutable, { type RecordOf } from 'immutable'

import { LoadingStatus } from 'constants/common'
import { type CustomDataRecord } from './custom-data.record'

export type DataManagementStateProps = {
  loadingState: LoadingStatus
  entities: Immutable.Map<string, CustomDataRecord>
  editingId: string | null
}
export type DataManagementStateRecord = RecordOf<DataManagementStateProps>

export const DataManagementStateFactory = Immutable.Record<DataManagementStateProps>({
  loadingState: LoadingStatus.INIT,
  entities: Immutable.Map(),
  editingId: null,
})
