import Immutable from 'immutable'
import { type availableIcons } from 'components/common/svg-icon'
import { type TypeValue } from 'com.batch/profile/infra/debug/models/shared.model'

export type Type = {
  label: string
  value: TypeValue
  icon: availableIcons
}

export const types = Immutable.List<Type>([
  { value: 'BOOL', label: 'BOOLEAN', icon: 'boolean' },
  { value: 'DATE', label: 'DATE', icon: 'date' },
  { value: 'DOUBLE', label: 'FLOAT', icon: 'number' },
  { value: 'LONG', label: 'INTEGER', icon: 'number' },
  { value: 'DISTANCE', label: 'POSITION', icon: 'text' },
  { value: 'STRING', label: 'STRING', icon: 'text' },
  { value: 'VERSION', label: 'VERSION', icon: 'version' },
  { value: 'URL', label: 'URL', icon: 'text' },
])
