import * as React from 'react'
import { type List } from 'immutable'

import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'
import { DataTableHeader, type Direction, type SortBy } from './data-table-header'
import { DataTableRow } from './data-table-row'
import { Table, TableBody } from 'components/common/table'
import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'
import { DataTableTemplate } from './data-table-template'
import { DataTableEmptySearch } from './data-table-empty-search'

type Props = {
  kind: DataKind
  datas: List<CustomDataRecord>
  onClickOverrideType?: (data: CustomDataRecord) => void
  onClickArchive: (data: CustomDataRecord) => void
  isArchivedShown: boolean
  searchTerms: string | null
}

export const DataTable = React.memo(
  ({
    kind,
    datas,
    onClickOverrideType,
    onClickArchive,
    isArchivedShown,
  }: Props): React.ReactNode => {
    const [sortBy, setSortBy] = React.useState<SortBy>('name')
    const [sortDirection, setSortDirection] = React.useState<Direction>('asc')

    const template = React.useMemo(() => {
      if (kind === DataKind.EVENT) return 'minmax(150px, 1fr) 130px 180px 180px 140px 100px'
      return 'minmax(150px, 1fr) 180px 180px 140px 100px'
    }, [kind])

    const sortData = React.useCallback(
      (data: List<CustomDataRecord> | undefined) => {
        if (data) {
          const result = data.sortBy((member: CustomDataRecord) => {
            if (sortBy === 'lastReceived') return member.lastReceivedAt
            if (sortBy === 'created') return member.createdAt
            return member.name
          })
          return sortDirection === 'asc' ? result : result.reverse()
        }
        return []
      },
      [sortDirection, sortBy]
    )

    const sortedDatas = React.useMemo(() => sortData(datas), [sortData, datas])

    const switchSort = React.useCallback(
      (clickedSortBy: SortBy) => () => {
        if (sortBy !== clickedSortBy) {
          setSortBy(clickedSortBy)
          setSortDirection('asc')
        } else setSortDirection(sortDirection === 'asc' ? 'dsc' : 'asc')
      },
      [sortBy, sortDirection]
    )

    const handleOnClickOverrideType = React.useCallback(
      (data: CustomDataRecord) => () => {
        if (onClickOverrideType) {
          onClickOverrideType(data)
        }
      },
      [onClickOverrideType]
    )

    const handleClickArchive = React.useCallback(
      (data: CustomDataRecord) => () => {
        onClickArchive(data)
      },
      [onClickArchive]
    )

    return (
      <Table template={template} rowHeight={52}>
        <DataTableHeader
          kind={kind}
          sortBy={sortBy}
          sortDirection={sortDirection}
          switchSort={switchSort}
        />
        <TableBody style={{ overflow: 'hidden' }} emptyTemplate={<DataTableTemplate kind={kind} />}>
          {sortedDatas.map((data: CustomDataRecord) => (
            <DataTableRow
              key={data.name}
              kind={data.kind}
              name={data.name}
              isArchivedShown={isArchivedShown}
              onClickArchive={handleClickArchive(data)}
              onClickOverrideType={handleOnClickOverrideType(data)}
            />
          ))}
          {datas.size === 0 && <DataTableEmptySearch />}
        </TableBody>
      </Table>
    )
  },
  (prevProps, nextProps) =>
    prevProps.isArchivedShown === nextProps.isArchivedShown &&
    prevProps.kind === nextProps.kind &&
    prevProps.searchTerms === nextProps.searchTerms &&
    prevProps.datas === nextProps.datas
)
