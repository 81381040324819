import styled from 'styled-components'

import { TableCellHeader } from 'components/common/table'
import { TableOrderContainer } from 'components/common/table/table.styles'

export const DataTableCellHeader = styled(TableCellHeader)`
  margin-left: 0;

  &:first-child {
    margin-left: 8px !important;
  }

  & > ${TableOrderContainer} {
    margin-left: 0 !important;
  }
`
