import { Button } from 'components/common/button'
import { colors, schemes } from 'components/styled/tokens'
import styled, { css } from 'styled-components'

export const WarningButton = styled(Button)`
  ${p =>
    p.kind === 'primary'
      ? css`
          background-color: ${colors.textWarning};

          &:hover,
          &:focus {
            background-color: ${schemes.orange['70']};
          }
        `
      : css`
          &,
          &:hover,
          &:focus {
            color: ${colors.textWarning};
          }
        `}
`
