import * as React from 'react'

import { TableHeader, TableCellOrder, TableCellHeader } from 'components/common/table'
import { DataTableCellHeader } from './data-table-header.styles'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

export type Direction = 'asc' | 'dsc'
export type SortBy = 'name' | 'lastReceived' | 'created'

type Props = {
  kind: DataKind
  sortBy: SortBy
  sortDirection: Direction
  switchSort: (clickedSort: string) => () => void
}

export const DataTableHeader = ({
  kind,
  sortBy,
  sortDirection,
  switchSort,
}: Props): React.ReactNode => (
  <TableHeader>
    <DataTableCellHeader>
      <TableCellOrder sort={sortBy === 'name' ? sortDirection : false} onClick={switchSort('name')}>
        {kind === DataKind.EVENT ? 'Events' : 'Attributes'} name
      </TableCellOrder>
    </DataTableCellHeader>
    {kind === DataKind.EVENT && <TableCellHeader>Attributes</TableCellHeader>}
    <DataTableCellHeader>
      <TableCellOrder
        align="left"
        sort={sortBy === 'lastReceived' ? sortDirection : false}
        onClick={switchSort('lastReceived')}
      >
        Last received
      </TableCellOrder>
    </DataTableCellHeader>
    <TableCellHeader>Sources</TableCellHeader>
    <DataTableCellHeader>
      <TableCellOrder
        sort={sortBy === 'created' ? sortDirection : false}
        onClick={switchSort('created')}
      >
        Created
      </TableCellOrder>
    </DataTableCellHeader>
    <TableCellHeader />
  </TableHeader>
)
