import * as React from 'react'

import { Popin } from 'components/common/popin/popin'
import { BoxBody, BoxFooter, BoxHeader, BoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { DataModalBox } from '../data-modal.styles'

import { useDispatch } from 'com.batch.common/react-redux'
import { archiveData } from 'com.batch/data-management/usecases/update-data'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'
import { showToast } from 'com.batch.redux/toaster'
import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'
import { WarningButton } from 'com.batch/data-management/ui/components/warning-button.styles'

type Props = {
  data: CustomDataRecord | null
  onClose: () => void
}

export const ArchiveModal = ({ data, onClose }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const dispatch = useDispatch()
  const handleConfirmClick = React.useCallback(() => {
    if (!data) {
      return
    }
    setIsLoading(true)
    dispatch(archiveData({ name: data.name, kind: data.kind }))
      .then(() => {
        dispatch(
          showToast({
            message: data.kind === DataKind.EVENT ? 'Event archived' : 'Attribute archived',
            kind: 'success',
          })
        )
        onClose()
      })
      .catch(e => dispatch(showToast({ message: e, kind: 'error' })))
      .finally(() => setIsLoading(false))
  }, [data, onClose, dispatch])

  return (
    <Popin opened={Boolean(data)} close={onClose}>
      <DataModalBox>
        <BoxHeader>
          <BoxTitle>Archive {data?.kind === DataKind.EVENT ? 'event' : 'attribute'}?</BoxTitle>
          <Button onClick={onClose}>
            <Icon icon="close" />
          </Button>
        </BoxHeader>
        <BoxBody>
          <p>
            This {data?.kind === DataKind.EVENT ? 'event' : 'attribute'} will no longer be available
            in the dashboard, particularly in orchestrations.
          </p>
          <p>
            However, it will not be permanently deleted and will remain indexed. You’ll be able to
            restore it later if needed.
          </p>
        </BoxBody>
        <BoxFooter>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <WarningButton
            intent="danger"
            kind="primary"
            onClick={handleConfirmClick}
            disabled={isLoading}
          >
            Yes, archive
          </WarningButton>
        </BoxFooter>
      </DataModalBox>
    </Popin>
  )
}
