import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import {
  ConfirmedCustomDataFactory,
  type ConfirmedCustomDataRecord,
} from 'com.batch/data-management/models/confirm-custom-data.record'
import { type DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

export type ConfirmDataReturned = { name: string; kind: DataKind }[]

type ConfirmDataAction = ReduxAction<'CONFIRM_DATA', null>
type ConfirmDataSuccessAction = ReduxAction<'CONFIRM_DATA_SUCCESS', ConfirmDataReturned>
type ConfirmDataFailureAction = ReduxAction<'CONFIRM_DATA_FAILURE', string>

export type ConfirmDataActions =
  | ConfirmDataAction
  | ConfirmDataSuccessAction
  | ConfirmDataFailureAction

export const confirmOneData = (
  id: string,
  kind: DataKind
): DispatchExtraBoundFn<Promise<ConfirmDataReturned>> => {
  const confirmedCustomData = ConfirmedCustomDataFactory({
    name: id,
    kind: kind,
  })
  return confirmData([confirmedCustomData])
}

export const confirmData = (
  attributes: ConfirmedCustomDataRecord[]
): DispatchExtraBoundFn<Promise<ConfirmDataReturned>> => {
  return async (dispatch, getState, { dmService }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: dmService.confirm({
        projectKey: project.projectKey,
        attributes,
      }),
      actionName: 'CONFIRM_DATA',
    })
  }
}
