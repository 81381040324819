import { combineReducers } from 'redux'

import app from './app'
import attribute from './attribute'
import attrValue from './attributeValue'
import audience from './audience'
import campaign from './campaign'
import cluster from './cluster'
import company from './company'
import content from './content'
import { senderIdentityReducer } from './corelogic/reducers/sender-identity.reducer'
import { dataCampaignReducer as dataCampaign } from './dataCampaign'
import project from './project'
import { QueryReducer as query } from './query/query'
import stat from './stat'
import { targetReducer } from './target/target'
import targeting from './targeting'
import template from './template'
import testDevice from './testDevice'
import theme from './theme'
import toaster from './toaster'
import user from './user'

import { audienceProfileReducer } from 'com.batch/audience/store/audience-profile.reducer'
import { dataManagementReducer } from 'com.batch/data-management/store/data-management.reducer'
import { inlineEditorReducer } from 'com.batch/message-builder/store/inline-editor.reducer'
import { labelsReducer } from 'com.batch/labels/store/labels.reducer'
import { messageReducer } from 'com.batch/message/store/message.reducer'
import { orchestrationAnalyticsReducer } from 'com.batch/orchestration-analytics/store/orchestration-analytics.reducer'
import { orchestrationListReducer } from 'com.batch/orchestration-list/store/orchestration-list.reducer'
import { orchestrationReducer } from 'com.batch/orchestration/store/orchestration.reducer'
import { profilebaseReducer } from 'com.batch/profilebase/store/profilebase.reducer'
import { profileReducer } from 'com.batch/profile/store/profile.reducer'
import { segmentsReducer } from 'com.batch/segments/store/segments.reducer'
import { templatingReducer } from 'com.batch/templating/store/templating.reducer'

export const rootReducer = combineReducers({
  app,
  attribute,
  attrValue,
  audience,
  audienceProfile: audienceProfileReducer,
  campaign,
  cluster,
  company,
  content,
  dataCampaign,
  dataManagement: dataManagementReducer,
  inlineEditor: inlineEditorReducer,
  message: messageReducer,
  messageTemplating: templatingReducer,
  orchestration: orchestrationReducer,
  orchestrationAnalytics: orchestrationAnalyticsReducer,
  orchestrationList: orchestrationListReducer,
  profile: profileReducer,
  profilebase: profilebaseReducer,
  project,
  projectLabels: labelsReducer,
  query,
  segments: segmentsReducer,
  senderIdentity: senderIdentityReducer,
  stat,
  target: targetReducer,
  targeting,
  template,
  testDevice,
  theme,
  toaster,
  user,
})
