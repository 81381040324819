import * as React from 'react'

import { EmptySearchWrapper } from './data-table-empty-search.styles'
import { Icon } from 'components/common/svg-icon'

export const DataTableEmptySearch = (): React.ReactElement => {
  return (
    <EmptySearchWrapper>
      <div>
        <Icon icon="notfound" />
        No attribute to display
      </div>
      <div>There are no attributes matching your search</div>
    </EmptySearchWrapper>
  )
}
