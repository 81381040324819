import Immutable, { type RecordOf } from 'immutable'

import { type TypeValue } from 'com.batch/profile/infra/debug/models/shared.model'

export type UpdateCustomAttributeProps = {
  projectKey: {
    textual: {
      text: string
    }
  }
  name: string
  isArchived?: boolean
  overriddenName?: string
  overriddenType?: TypeValue
}

export type UpdateCustomAttributeRecord = RecordOf<UpdateCustomAttributeProps>

export const UpdateCustomAttributeFactory = Immutable.Record<UpdateCustomAttributeProps>({
  projectKey: {
    textual: {
      text: 'string',
    },
  },
  name: '',
  isArchived: false,
  overriddenName: undefined,
  overriddenType: undefined,
})

export type UpdateCustomEventProps = {
  projectKey: {
    textual: {
      text: string
    }
  }
  name: string
  isArchived?: boolean
  overriddenName?: string
}

export type UpdateCustomEventRecord = RecordOf<UpdateCustomEventProps>

export const UpdateCustomEventFactory = Immutable.Record<UpdateCustomEventProps>({
  projectKey: {
    textual: {
      text: 'string',
    },
  },
  name: '',
  isArchived: false,
  overriddenName: undefined,
})

export type UpdateCustomTagProps = {
  projectKey: {
    textual: {
      text: string
    }
  }
  name: string
  isArchived?: boolean
  overriddenName?: string
}
export type UpdateCustomTagRecord = RecordOf<UpdateCustomTagProps>
export const UpdateCustomTagFactory = Immutable.Record<UpdateCustomTagProps>({
  projectKey: {
    textual: {
      text: 'string',
    },
  },
  name: '',
  isArchived: false,
  overriddenName: undefined,
})
