import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { generateUrl } from './router'
import { type ChannelInUrl } from './router-types'

import { type pushCampaignSendType } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'

export const generateOrchestrationUrl = ({
  sendType,
  companyId,
  projectId,
  token,
  channel,
  page,
}: {
  token: string
  projectId?: string | null | undefined
  channel: ChannelInUrl
  companyId: number
  page: 'form' | 'review' | 'analytics'
  sendType: pushCampaignSendType | 'in-app'
}): string => {
  const schedulingType: schedulingType =
    sendType === 'now' || sendType === 'scheduled' ? 'campaigns' : 'automations'

  if (channel === 'email' || channel === 'sms' || channel === 'push' || channel === null) {
    const routeNameBasedOnSendType =
      sendType === 'trigger' ? '_trigger' : sendType === 'recurring' ? '_recurring' : '_campaign'

    const pageType = page === 'analytics' ? '_analytics' : token === 'new' ? '_new' : '_edit'

    if (sendType === 'trigger') {
      return generateUrl(
        `project${routeNameBasedOnSendType}${pageType}`,
        token === 'new'
          ? {
              companyId,
              projectId,
            }
          : {
              companyId,
              projectId,
              token,
            }
      )
    }
    return generateUrl(
      `project${routeNameBasedOnSendType}${pageType}`,
      token === 'new'
        ? {
            companyId,
            projectId,
            channel,
          }
        : {
            companyId,
            projectId,
            channel,
            token,
          }
    )
  }
  const optionalCampaignType =
    schedulingType === 'automations'
      ? { campaignType: sendType === 'in-app' ? 'in-app' : 'push' }
      : {}
  if (projectId) {
    if (page === 'review' || page === 'analytics') {
      return generateUrl(`${schedulingType.slice(0, -1)}_data_with_project`, {
        id: token,
        companyId,
        projectId,
        ...optionalCampaignType,
        appId: channel.appId,
        tab: page,
      })
    }
    return generateUrl(`${schedulingType.slice(0, -1)}_form_with_project`, {
      id: token,
      companyId,
      projectId,
      ...optionalCampaignType,
      appId: channel.appId,
    })
  } else {
    if (page === 'review' || page === 'analytics') {
      return generateUrl(`${schedulingType.slice(0, -1)}_data`, {
        id: token,
        companyId,
        ...optionalCampaignType,
        appId: channel.appId,
        tab: page,
      })
    }
    return generateUrl(`${schedulingType.slice(0, -1)}_form`, {
      id: token,
      companyId,
      ...optionalCampaignType,
      appId: channel.appId,
    })
  }
}

export const generateOrchestrationListUrl = ({
  projectId,
  channel,
  companyId,
  scheduling,
}: {
  projectId?: string
  channel: ChannelInUrl
  companyId: number
  scheduling: schedulingType
}): string => {
  return projectId
    ? channel === 'email' || channel === 'sms' || channel === 'push' || channel === null
      ? generateUrl(
          scheduling === 'automations' ? 'project_automations_list' : 'project_campaign_list',
          {
            companyId,
            projectId,
          }
        )
      : generateUrl('campaign_list_with_project', {
          projectId,
          companyId,
          appId: channel.appId,
          schedulingType: scheduling,
        })
    : generateUrl('campaign_list', {
        // ce cas n'arrive pas
        appId:
          channel === 'email' || channel === null || channel === 'sms' || channel === 'push'
            ? 0
            : channel.appId,
        companyId,
        schedulingType: scheduling,
      })
}

export type settingsTab =
  | 'global'
  | 'push'
  | 'audiences'
  | 'team'
  | 'email'
  | 'custom-data'
  | 'themes'
  | 'profiles'
  | 'debug'
  | 'privacy'
  | 'labels'
  | 'cappings'

export const coerceSettingsTab = (pathname: string): settingsTab => {
  const pathParts = pathname.split('/')
  const name = pathParts.pop()

  switch (name) {
    case 'push':
    case 'audiences':
    case 'email':
    case 'team':
    case 'custom-data':
    case 'themes':
    case 'profiles':
    case 'debug':
    case 'privacy':
    case 'labels':
    case 'cappings':
      return name
    case 'new':
      if (pathParts.pop() === 'themes') {
        return 'themes'
      }
      return 'global'
    default:
      return 'global'
  }
}

export const generateSettingsUrl = ({
  activeTab,
  projectId,
  companyId,
  channel,
}: {
  activeTab: settingsTab
  projectId?: string
  companyId: number
  channel: ChannelInUrl
}): string => {
  if (channel === 'push') {
    throw new Error('Settings are scoped to an app for push, or to email')
  }
  if (channel === 'email' || channel === 'sms' || channel === null) {
    return generateUrl('project_settings', {
      companyId: companyId,
      projectId: projectId,
      activeTab,
    })
  }
  return projectId
    ? generateUrl('app_settings_with_project', {
        projectId,
        companyId,
        activeTab,
        appId: channel.appId,
      })
    : generateUrl('app_settings', { companyId, activeTab, appId: channel.appId })
}

export const generateAnalyticsUrl = (params: {
  projectId?: string
  companyId: number
  appId: number
  tab?: 'reach' | 'push'
  kind?: 'all' | 'campaign' | 'transac'
}): string => {
  return params.projectId
    ? generateUrl('analytics_stats_with_project', params)
    : generateUrl('analytics_stats', params)
}
type callbackSignature = (arg1: {
  tab?: 'reach' | 'push'
  kind?: 'all' | 'campaign' | 'transac'
}) => string

export const useGenerateAnalyticsUrl = (): callbackSignature => {
  const maybeProject = useSelector(optionalCurrentProjectSelector)
  const app = useSelector(currentAppSelector)
  const callback = React.useCallback(
    ({ tab, kind }: { tab?: 'reach' | 'push'; kind?: 'all' | 'campaign' | 'transac' }) => {
      const params: {
        projectId?: string
        companyId: number
        appId: number
        tab?: 'reach' | 'push'
        kind?: 'all' | 'campaign' | 'transac'
      } = {
        companyId: app?.companyId ?? 0,
        appId: app?.id ?? 0,
      }
      if (maybeProject) params.projectId = maybeProject.id
      if (tab) params.tab = tab
      if (kind) params.kind = kind
      return generateAnalyticsUrl(params)
    },
    [app?.companyId, app?.id, maybeProject]
  )
  return callback
}

export type profilesTab = 'audiences' | 'privacy' | 'segments' | 'find' | 'data-management' | ''

export const coerceProfilesTab = (name: string): profilesTab => {
  switch (name) {
    case 'audiences':
    case 'find':
    case 'privacy':
      return name
    case 'data-management':
    case 'attributes':
    case 'events':
      return 'data-management'
    default:
      return ''
  }
}

export const generateProfilesUrl = (params: {
  activeTab?: profilesTab
  projectId?: string
  companyId: number
  appId?: number
}): string => {
  if (params.appId) {
    return generateUrl('project_profiles_app', params)
  }
  return generateUrl('project_profiles', params)
}

export const generateProfilebaseUrl = (params: {
  projectId: string
  companyId: number
}): string => {
  return generateUrl('profilebase', params)
}

export const generateProfilesOrSettingsUrl = (params: {
  activeTab?: profilesTab
  projectId?: string
  companyId: number
  appId?: number
}): string => {
  if (params.projectId) {
    return generateProfilesUrl(params)
  }
  return generateUrl('app_settings', {
    companyId: params.companyId,
    appId: params.appId,
    activeTab: params.activeTab,
  })
}

export type DataManagementTab = 'attributes' | 'events'

export const generateDataManagementUrl = ({
  tab = 'attributes',
  search,
  archived,
}: {
  tab?: DataManagementTab
  search?: string | null
  archived?: boolean
}): string => {
  const projectId = window?.initialData?.projectId
  const companyId = window?.company?.id
  const params = new URLSearchParams()
  const cleanSearch = search?.trim()
  if (archived) {
    params.append('archived', 'true')
  }
  if (cleanSearch) {
    params.append('search', cleanSearch)
  }
  return (
    generateUrl('project_profiles_data_management', {
      companyId,
      projectId,
      tab,
    }) + (params.toString() ? `?${params.toString()}` : '')
  )
}
