import * as React from 'react'

import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

import {
  dmEditingIdSelector,
  selectCustomDataById,
} from 'com.batch/data-management/store/data-management.selectors'
import { DataTableRowContent } from 'com.batch/data-management/ui/components/data-table/data-table-row-content'
import {
  DataRow,
  EditingRow,
} from 'com.batch/data-management/ui/components/data-table/data-table-row.styles'
import { Input } from 'components/form'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { setEditingId, updateData } from 'com.batch/data-management/usecases/update-data'
import { showToast } from 'com.batch.redux/toaster'

type Props = {
  name: string
  isArchivedShown: boolean
  kind: DataKind
  onClickOverrideType?: () => void
  onClickArchive: () => void
}

export const DataTableRow = React.memo(
  ({
    name,
    onClickOverrideType,
    onClickArchive,
    kind,
    isArchivedShown,
  }: Props): React.ReactNode => {
    const dispatch = useDispatch()
    const customDataById = selectCustomDataById()
    const data = useSelector(state => customDataById(state, `${kind}_${name}`))
    const editingId = useSelector(dmEditingIdSelector)
    const isEditing = React.useMemo(() => editingId === name, [editingId, name])
    const [newName, setNewName] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)

    const handleNameChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => setNewName(evt.target.value),
      []
    )

    const handleRenamingClick = React.useCallback(() => {
      setIsLoading(true)
      dispatch(updateData({ overriddenName: newName, name, kind }))
        .then(() => {
          dispatch(
            showToast({
              message: kind === DataKind.EVENT ? 'Event renamed' : 'Attribute renamed',
              kind: 'success',
            })
          )
          dispatch(setEditingId(null))
        })
        .catch(e => dispatch(showToast({ message: e, kind: 'error' })))
        .finally(() => setIsLoading(false))
    }, [dispatch, newName, name, kind])

    const handleOnClickCancelRename = React.useCallback(() => {
      dispatch(setEditingId(null))
    }, [dispatch])

    React.useEffect(() => {
      if (!isEditing) setNewName(data?.overriddenName ?? '')
    }, [isEditing, data?.overriddenName])

    if (!data || (data.archivedAt && !isArchivedShown)) return null

    return (
      <DataRow $isArchived={Boolean(data.archivedAt)}>
        {!isEditing ? (
          <DataTableRowContent
            onClickArchive={onClickArchive}
            data={data}
            onClickOverrideType={onClickOverrideType}
          />
        ) : (
          <EditingRow>
            <Input value={newName} placeholder={name} onChange={handleNameChange} />
            <Button onClick={handleOnClickCancelRename}>Cancel</Button>
            <Button
              intent="action"
              kind="secondary"
              disabled={
                (!data?.overriddenName && !newName) || data?.overriddenName === newName || isLoading
              }
              onClick={handleRenamingClick}
            >
              <Icon icon="edit" style={{ marginRight: 8 }} />
              Rename
            </Button>
          </EditingRow>
        )}
      </DataRow>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name === nextProps.name &&
      prevProps.isArchivedShown === nextProps.isArchivedShown &&
      prevProps.kind === nextProps.kind
    )
  }
)
