import type Immutable from 'immutable'
import { type List } from 'immutable'
import { createSelector } from 'reselect'

import { type State } from 'com.batch.redux/_records'
import { type LoadingStatus } from 'constants/common'

import { type DataManagementStateRecord } from 'com.batch/data-management/models/data-management-state.record'
import { DataKind } from '../infra/types/data-kind.types'
import { type CustomDataRecord } from '../models/custom-data.record'

type extract<T> = (arg1: State) => T

export const dmSelector = (state: State): DataManagementStateRecord => {
  return state.dataManagement
}

export const dmEditingIdSelector: extract<string | null> = createSelector(
  dmSelector,
  dmState => dmState.editingId
)

export const dmLoadingStateSelector: extract<LoadingStatus> = createSelector(
  dmSelector,
  dmState => dmState.loadingState
)

export const entitiesSelector: extract<Immutable.Map<string, CustomDataRecord>> = createSelector(
  dmSelector,
  dmState => dmState.entities
)

// attributes selectors
export const entitiesAttributesSelector: extract<List<CustomDataRecord>> = createSelector(
  dmSelector,
  dmState =>
    dmState.entities
      .filter(entity => entity.kind === DataKind.ATTRIBUTE || entity.kind === DataKind.TAGS)
      .toList()
)
export const newAttributesSelector: extract<List<CustomDataRecord>> = createSelector(
  entitiesAttributesSelector,
  attributes => attributes.filter(attribute => !attribute.confirmedAt)
)
export const attributesSelector: extract<List<CustomDataRecord>> = createSelector(
  entitiesAttributesSelector,
  attributes => {
    return attributes.filter(attribute => attribute.confirmedAt)
  }
)

export const countNewAttributesSelector: extract<number> = createSelector(
  newAttributesSelector,
  newAttributes => newAttributes.size
)
export const countAttributesSelector: extract<number> = createSelector(
  attributesSelector,
  attributes => attributes.filter(a => !a.archivedAt).size
)

// events selectors
export const entitiesEventsSelector: extract<List<CustomDataRecord>> = createSelector(
  dmSelector,
  dmState => dmState.entities.filter(entity => entity.kind === DataKind.EVENT).toList()
)
export const newEventsSelector: extract<List<CustomDataRecord>> = createSelector(
  entitiesEventsSelector,
  events => events.filter(event => !event.confirmedAt)
)
export const eventsSelector: extract<List<CustomDataRecord>> = createSelector(
  entitiesEventsSelector,
  events => events.filter(event => event.confirmedAt)
)
export const countNewEventsSelector: extract<number> = createSelector(
  newEventsSelector,
  newEvents => newEvents.size
)
export const countEventsSelector: extract<number> = createSelector(
  eventsSelector,
  events => events.filter(a => !a.archivedAt).size
)

const selectEntities = (state: State) => state.dataManagement.entities

export const selectCustomDataById = () =>
  createSelector([selectEntities, (_, id) => id], (records, id) => records.get(id))
