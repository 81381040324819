import * as React from 'react'
import { type List } from 'immutable'

import { NewDataBlockBody, NewDataBlockHeader, NewDataBlockWrapper } from './new-data-block.styles'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { CollapseChevron, CollapsePanel } from 'com.batch/shared/ui/component/collapse/collapse'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'
import { useTheme } from 'styled-components'
import { useRef } from 'react'
import { DataTable } from 'com.batch/data-management/ui/components/data-table/data-table'
import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'

type Props = {
  kind: DataKind
  datas: List<CustomDataRecord>
  confirmAll: () => void
  onClickOverrideType?: (data: CustomDataRecord) => void
  onClickArchive: (data: CustomDataRecord) => void
  searchTerms: string | null
  isArchivedShown: boolean
}

export const NewDataBlock = ({
  kind,
  datas,
  confirmAll,
  onClickOverrideType,
  searchTerms,
  isArchivedShown,
  onClickArchive,
}: Props): React.ReactNode => {
  const ref = useRef<HTMLDivElement>(null)
  const { isLoading } = useTheme()
  const [isOpen, setIsOpen] = React.useState(true)
  const hasSearch = React.useMemo(() => Boolean(searchTerms), [searchTerms])

  const title = React.useMemo(() => {
    const dataSize = datas ? datas.size : 0
    return (
      <div className="block-new-title">
        {dataSize} new {kind === DataKind.ATTRIBUTE ? 'attribute' : 'event'}
        {dataSize > 1 && 's'}
        {searchTerms ? ' matching your search' : ' available'}
      </div>
    )
  }, [datas, kind, searchTerms])

  const handleOpenSwitch = React.useCallback(() => setIsOpen(!isOpen), [isOpen])

  const handleOnClickOverrideType = React.useCallback(
    (data: CustomDataRecord) => {
      if (onClickOverrideType) {
        onClickOverrideType(data)
      }
    },
    [onClickOverrideType]
  )

  if (isLoading) return null

  return (
    <NewDataBlockWrapper ref={ref}>
      <NewDataBlockHeader>
        <div>
          {title}
          {!searchTerms && (
            <div className="block-new-subtitle">
              When ready, confirm each {kind === DataKind.ATTRIBUTE ? 'attribute' : 'event'} and it
              will become available in your orchestrations
            </div>
          )}
        </div>
        {!searchTerms && (
          <React.Fragment>
            <Button
              intent="action"
              kind="secondary"
              onClick={confirmAll}
              style={{ visibility: hasSearch ? 'hidden' : 'initial' }}
            >
              <Icon icon="check" style={{ marginRight: 8 }} />
              Confirm all
            </Button>
            <Button intent="neutral" kind="inlineDark" onClick={handleOpenSwitch}>
              <CollapseChevron isOpened={isOpen} />
            </Button>
          </React.Fragment>
        )}
      </NewDataBlockHeader>

      <CollapsePanel id="new-attributes" isOpened={isOpen} disableAnimation={!ref.current}>
        <NewDataBlockBody>
          <DataTable
            kind={kind}
            datas={datas}
            onClickOverrideType={handleOnClickOverrideType}
            onClickArchive={onClickArchive}
            searchTerms={searchTerms}
            isArchivedShown={isArchivedShown}
          />
        </NewDataBlockBody>
      </CollapsePanel>
    </NewDataBlockWrapper>
  )
}
