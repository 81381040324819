import styled, { keyframes } from 'styled-components'
import { colors } from 'components/styled/tokens'

const SpinnerKeyFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  width: 14px;
  height: 14px;
  position: relative;
  color: transparent;

  > * {
    opacity: 0;
  }

  &:after {
    display: block;
    position: absolute;
    left: calc(50% - 7px);
    width: 14px;
    height: 14px;
    border: 1.5px solid ${colors.textDisabled};
    border-top: 1.5px solid transparent;
    border-radius: 50%;
    content: '';
    animation: ${SpinnerKeyFrames} 0.5s linear infinite;
  }
`
