import styled from 'styled-components'

import { colors, texts, schemes } from 'components/styled/tokens'
import { TableContainer } from 'components/common/table/table.styles'

export const NewDataBlockWrapper = styled.div`
  margin-bottom: 28px;
  border-radius: 6px;
  background-color: ${colors.fillAction};
  box-shadow: 0px 0px 0px 4px ${colors.fillAction};
`

export const NewDataBlockHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) auto auto;
  grid-column-gap: 8px;
  align-items: center;
  padding: 12px 8px 16px 16px;
  color: white;

  .block-new-title {
    font: ${texts.h2.font};
    margin-bottom: 4px;
    color: ${schemes.lightlucent['1']};
  }

  .block-new-subtitle {
    color: ${schemes.lightlucent['60']};
  }
`

export const NewDataBlockBody = styled.div`
  border-radius: 4px;

  ${TableContainer} {
    margin-bottom: 0;
    border: none;
  }
`
