import styled from 'styled-components'
import { textLight, textNeutral } from 'components/styled/tokens/colors'
import { ButtonNavLink } from 'components/common/button'
import { colors } from 'components/styled/tokens'
import { texts } from 'components/styled/tokens'

type TabContainerProps = {
  $active?: boolean
}

export const TabsList = styled.div`
  display: flex;
  gap: 8px;
`

export const TabContainer = styled(ButtonNavLink)<TabContainerProps>`
  gap: 8px;
  position: relative;
  color: ${textLight};
  overflow: initial;
  &:hover {
    color: ${textNeutral};
  }
  ${props =>
    props.$active &&
    `
      color: ${textNeutral};
  `}
  &:focus {
    color: ${textNeutral};
  }
`

export const TabCounter = styled.span`
  display: flex;
  padding: 0 4px;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  box-shadow: 0 0 0 1px ${colors.stroke};
  background: #ffffff;
  ${texts.legend}
  margin-right: -4px;
  min-width: 20px;
`

export const TabNewCounter = styled.span`
  display: flex;
  height: 14px;
  min-width: 14px;
  padding: 2px 4px 2px 4px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(100% - 21px);
  top: -2px;
  border-radius: 7px;
  font-size: 10px;
  font-weight: 600;
  background: ${colors.fillAction};
  color: ${colors.textContrast};
  box-shadow: 0 1px 3px 0 ${colors.opacifyDefault};
`
