import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'
import { type ConfirmedCustomDataRecord } from 'com.batch/data-management/models/confirm-custom-data.record'
import { parseCustomDatas } from 'com.batch/data-management/infra/parses/custom-data.parse'
import {
  type UpdateCustomAttributeRecord,
  type UpdateCustomEventRecord,
  type UpdateCustomTagRecord,
} from 'com.batch/data-management/models/update-custom-attribute.record'
import { type FetchDataSuccessReturned } from 'com.batch/data-management/usecases/fetch-data'
import { type UpdateDataReturned } from 'com.batch/data-management/usecases/update-data'
import { type ConfirmDataReturned } from 'com.batch/data-management/usecases/confirm-data'

enum EventAttributeKind {
  PRIMITIVE = 'PRIMITIVE',
  ARRAY = 'ARRAY',
  OBJECT = 'OBJECT',
}

type Type =
  | 'NULLVALUE'
  | 'BOOL'
  | 'LONG'
  | 'DURATION'
  | 'DISTANCE'
  | 'DOUBLE'
  | 'DATE'
  | 'VERSION'
  | 'STRING'
  | 'URL'
  | 'ARRAY'
  | 'OBJECT'
  | 'LABEL'

interface EventAttribute {
  name: string
  type: Type
  kind: EventAttributeKind
}

interface CustomEvent {
  archivedAt?: string
  attributes?: EventAttribute[]
  confirmedAt?: string
  createdAt: string
  hasLabel: boolean
  hasTag: boolean
  lastReceivedAt: string
  name: string
  overriddenName?: string
  platformLastReceivedAt: {
    [key: string]: string
  }
  updatedAt: string
}

interface CustomAttribute {
  archivedAt?: string
  confirmedAt?: string
  createdAt: string
  lastReceivedAt: string
  lastReceivedType: Type
  name: string
  overriddenName?: string
  overriddenType?: Type
  platformLastReceivedAt: {
    [key: string]: string
  }
  updatedAt: string
}

interface CustomTag {
  archivedAt?: string
  confirmedAt?: string
  createdAt: string
  lastReceivedAt: string
  name: string
  overriddenName?: string
  platformLastReceivedAt: {
    [key: string]: string
  }
  updatedAt: string
}

export interface CustomData {
  attribute?: CustomAttribute
  event?: CustomEvent
  tag?: CustomTag
}

interface FetchListResponse {
  results: CustomData[]
}

type UpdateRequest = {
  attribute?: UpdateCustomAttributeRecord
  event?: UpdateCustomEventRecord
  tag?: UpdateCustomTagRecord
}

type ConfirmRequest = {
  projectKey: string
  attributes: ConfirmedCustomDataRecord[]
}

export const dmService = {
  fetchList: async ({ projectKey }): Promise<FetchDataSuccessReturned> => {
    try {
      const response = await request.post<FetchListResponse>(
        generateUrl('api_grpc_dm_service', {
          methodName: 'List',
        }),
        {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
        }
      )

      return parseCustomDatas(response.results)
    } catch (e: any) {
      console.error(e)
      throw e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  update: async ({ attribute, event, tag }: UpdateRequest): Promise<UpdateDataReturned> => {
    try {
      await request.post<undefined>(
        generateUrl('api_grpc_dm_service', {
          methodName: 'Update',
        }),
        {
          attribute,
          event,
          tag,
        }
      )
      return { attribute, event, tag }
    } catch (e: any) {
      throw e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  confirm: async ({ projectKey, attributes }: ConfirmRequest): Promise<ConfirmDataReturned> => {
    try {
      await request.post<undefined>(
        generateUrl('api_grpc_dm_service', {
          methodName: 'Confirm',
        }),
        {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
          confirmations: attributes,
        }
      )
      return attributes
    } catch (e: any) {
      throw e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
}
