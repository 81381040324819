import Immutable, { type RecordOf } from 'immutable'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'

import { type EventAttributeRecord } from './event-attribute.record'
import { type TypeValue } from 'com.batch/profile/infra/debug/models/shared.model'

type CustomDataProps = {
  archivedAt: string | null
  attributes: Immutable.List<EventAttributeRecord>
  confirmedAt: string | null
  createdAt: string
  hasLabel: boolean
  hasTag: boolean
  id: string
  kind: DataKind
  lastReceivedAt: string
  lastReceivedType: TypeValue
  name: string
  overriddenName: string | null
  overriddenType: TypeValue | null
  platformLastReceivedAt: Immutable.Map<string, string>
  updatedAt: string | null
}

export type CustomDataRecord = RecordOf<CustomDataProps>

export const CustomDataFactory = Immutable.Record<CustomDataProps>({
  archivedAt: null,
  attributes: Immutable.List([]),
  confirmedAt: null,
  createdAt: '',
  hasLabel: false,
  hasTag: false,
  id: '',
  kind: DataKind.UNKNOWN,
  lastReceivedAt: '',
  lastReceivedType: 'NULLVALUE',
  name: '',
  overriddenName: null,
  overriddenType: null,
  platformLastReceivedAt: Immutable.Map(),
  updatedAt: null,
})
