import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'components/styled/tokens'

const ApiBadgeWrapper = styled.div`
  display: flex;
  width: 19px;
  height: 15px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 9px;
  font-weight: 600;
  line-height: 9px;
  letter-spacing: -0.18px;
  background-color: ${colors.textLight};
  margin-right: 8px;
`

export const ApiBadge = (): React.ReactElement => <ApiBadgeWrapper>API</ApiBadgeWrapper>
