import React from 'react'
import { Grid } from 'components/common/grid'
import { Title } from 'components/styled/text'
import { TabsList } from 'com.batch/shared/ui/component/tab/tab.styles'
import { Tab } from 'com.batch/shared/ui/component/tab/tab'
import {
  type DataManagementTab,
  generateDataManagementUrl,
} from 'com.batch.common/router/url-generator-helper'
import { FilterSearch } from 'components/filter'
import { Separator } from 'components/styled/utils'
import {
  ArchivedSwitch,
  FiltersWrapper,
} from 'com.batch/data-management/ui/components/data-management-header/data-management-header.styles'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'components/common/svg-icon'
import { useSelector } from 'react-redux'
import {
  countAttributesSelector,
  countEventsSelector,
  countNewAttributesSelector,
  countNewEventsSelector,
} from 'com.batch/data-management/store/data-management.selectors'
import { type LoadingStatus } from 'constants/common'

type Props = {
  isArchivedShown: boolean
  isAttributesTab: boolean
  loadingState: LoadingStatus
  search: string | null
}

let searchTimeout: ReturnType<typeof setTimeout> | null = null

export const DataManagementHeader = ({
  isArchivedShown,
  isAttributesTab,
  loadingState,
  search,
}: Props): React.ReactNode => {
  const navigate = useNavigate()
  const countAttributes = useSelector(countAttributesSelector)
  const countNewAttributes = useSelector(countNewAttributesSelector)
  const countEvents = useSelector(countEventsSelector)
  const countNewEvents = useSelector(countNewEventsSelector)
  const [searchTerms, setSearchTerms] = React.useState(search ?? '')
  const [isSearchActive, setIsSearchActive] = React.useState(false)
  const [isArchived, setIsArchived] = React.useState(isArchivedShown)

  const isLoading = React.useMemo(() => loadingState === 'LOADING', [loadingState])

  const getTabUrl = React.useCallback(
    (tab: DataManagementTab) => {
      return generateDataManagementUrl({
        tab,
        archived: isArchived,
        search,
      })
    },
    [isArchived, search]
  )

  const handleOnFocusSearch = React.useCallback(() => {
    setIsSearchActive(true)
  }, [])

  const handleOnBlurSearch = React.useCallback(() => {
    if (!searchTerms) {
      setIsSearchActive(false)
    }
  }, [searchTerms])

  const handleOnChange = React.useCallback(
    (value: string) => {
      setSearchTerms(value)
      setIsArchived(false)
      if (searchTimeout) clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        navigate(
          generateDataManagementUrl({
            search: value,
            tab: isAttributesTab ? 'attributes' : 'events',
            archived: false,
          })
        )
      }, 500)
    },
    [isAttributesTab, navigate]
  )

  const handleOnToggleArchived = React.useCallback(
    (archived: boolean) => {
      setIsArchived(archived)
      navigate(
        generateDataManagementUrl({
          search: searchTerms,
          tab: isAttributesTab ? 'attributes' : 'events',
          archived,
        })
      )
    },
    [navigate, searchTerms, isAttributesTab]
  )

  const handleOnClearSearch = React.useCallback(() => {
    setIsSearchActive(false)
  }, [])

  React.useEffect(() => {
    if (search) {
      setIsSearchActive(true)
    }
    setSearchTerms(search ?? '')
  }, [search])

  return (
    <Grid template={'550px 1fr'} margin={[10, 0, 28, 0]}>
      <div style={{ display: 'flex', gap: '16px' }}>
        <Title overEmptyState mb={0}>
          Data management
        </Title>
        <TabsList>
          <Tab
            track="data-management-attributes-tab"
            active={isAttributesTab}
            counter={countAttributes}
            isLoading={isLoading}
            newCounter={countNewAttributes}
            to={getTabUrl('attributes')}
          >
            Attributes
          </Tab>
          <Tab
            track="data-management-events-tab"
            active={!isAttributesTab}
            counter={countEvents}
            isLoading={isLoading}
            newCounter={countNewEvents}
            to={getTabUrl('events')}
          >
            Events
          </Tab>
        </TabsList>
      </div>
      <FiltersWrapper>
        <FilterSearch
          value={searchTerms}
          onChange={handleOnChange}
          onFocus={handleOnFocusSearch}
          onBlur={handleOnBlurSearch}
          onClear={handleOnClearSearch}
          expandedMaxWidth={280}
          width={isSearchActive ? 280 : 100}
          style={{ width: isSearchActive ? '280px' : '100px' }}
        />
        <div style={{ opacity: isSearchActive ? 0 : 1 }}>
          <Separator />
          <ArchivedSwitch isActive={isArchived} onChange={handleOnToggleArchived}>
            View archived <Icon icon="archive" />
          </ArchivedSwitch>
        </div>
      </FiltersWrapper>
    </Grid>
  )
}
